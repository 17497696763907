<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div slot="header">
              <h4 class="card-title">List Of Point By Revenue</h4>
            </div>
            <router-link
              :to="{ name: 'Create Point By Revenue' }"
              :disabled="!hasRole('create')"
              class="btn pull-right btn-border"
              >ADD POINT BY</router-link
            >
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-11">
            <el-table v-loading="loading" :data="pointByAllNonBasic">
              <el-table-column
                min-width="300"
                prop="label"
                label="Name"
              ></el-table-column>
              <el-table-column min-width="200" prop="childs" label="Revenue">
                <template scope="scope">
                  {{ scope.row.childs.map(v => v.child.label).join(", ") }}
                </template>
              </el-table-column>

              <el-table-column
                min-width="150"
                header-align="center"
                label="Actions"
              >
                <template slot-scope="scope">
                  <div class="text-center table-actions">
                    <el-tooltip
                      content="Edit"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="editBasicPointSettings(scope.row)"
                        type="success"
                        size="sm"
                        icon
                        :disabled="!hasRole('update')"
                      >
                        <i class="now-ui-icons ui-2_settings-90"></i>
                      </n-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Delete"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="
                          confirmDeleteBasicPointSettings(scope.row)
                        "
                        type="danger"
                        size="sm"
                        icon
                        :disabled="!hasRole('delete')"
                      >
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Table, TableColumn, Loading, MessageBox } from "element-ui";
import { Switch } from "src/components/index";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Helpers from "@/util/helper";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch
  },
  created: function() {
    userRolesCheck("read", "point_earning_rules");
    this.loadData();
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("pointBy", ["pointByAllNonBasic"])
  },
  methods: {
    ...mapActions("pointBy", ["getPointByAllNonBasic", "deletePointBy"]),
    hasRole(role) {
      return findUserRole(role, "point_earning_rules");
    },
    loadData: function() {
      this.getPointByAllNonBasic({ nonBasic: 1 })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    editBasicPointSettings(data) {
      this.$router.push({
        name: "Update Point By Revenue",
        params: { code: data.code, slug: Helpers.convertToSlug(data.label) }
      });
    },
    confirmDeleteBasicPointSettings(data) {
      MessageBox.confirm(
        `This will permanently delete point by revenue "${data.label}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deletePointBy(data)
            .then(response => {
              loadingInstance.close();
              this.$notify({
                message: "Delete point by revenue success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
              });
            })
            .catch(error => {
              console.log(error);
              loadingInstance.close();
              this.$notify({
                message:
                  (error.response.data &&
                    error.response.data.error &&
                    error.response.data.error.message) ||
                  "Delete point by revenue failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style></style>
