<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Email List</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <router-link
            :to="{ name: 'Campaign Email - Create Email' }"
            :disabled="!hasRole('create')"
            class="btn pull-right btn-border"
            >CREATE EMAIL</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <fg-input
                style="margin-top: 10px"
                placeholder="Subject"
                addon-right-icon="fa fa-search"
                v-model="filter.search"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <el-select
                class="select-info"
                style="width: 45%"
                size="large"
                placeholder="Status All"
                v-model="filter.status"
              >
                <el-option
                  v-for="option in statusList"
                  class="select-info"
                  :value="option.code"
                  :label="option.label"
                  :key="option.code"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="pull-right">
            <n-button
              @click.native="btnClearFilterClick"
              class="ml-2 btn-border"
              type="info"
              >CLEAR FILTER</n-button
            >
            <n-button @click.native="loadData" class="btn" type="info"
              >APPLY FILTER</n-button
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table v-loading="loading" :data="campaign.list">
            <el-table-column min-width="200" label="Campaign Name">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column min-width="200" label="Subject">
              <template slot-scope="scope">
                {{ parseSubject(scope.row.langs) }}
              </template>
            </el-table-column>
            <el-table-column min-width="150" label="Recipient">
              <template slot-scope="scope">
                <span style="text-transform: capitalize">
                  {{ scope.row.recipient.type | format }}
                </span>
              </template>
            </el-table-column>
            <el-table-column min-width="150" label="Sending Date">
              <template slot-scope="scope">
                {{ parseDateTime(scope.row.scheduled_at) }}
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              align="center"
              prop="status"
              label="Status"
            >
              <template slot-scope="scope">
                {{ parseUCFirst(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              header-align="right"
              label="Actions"
            >
              <template slot-scope="scope">
                <div class="text-right table-actions">
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button
                      @click.native="handleEdit(scope.$index, scope.row)"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip
                    :content="scope.row.status === 'draft' ? 'Activate' : 'Save to Draft'"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="confirmToggleStatus(scope.$index, scope.row)"
                      type="info"
                      size="sm"
                      icon
                      :disabled="
                        !hasRole('edit') ||
                          scope.row.status === 'in_progress' ||
                          scope.row.status === 'completed'
                      "
                    >
                      <i
                        :class="[
                          scope.row.status === 'draft'
                            ? 'now-ui-icons media-1_button-play'
                            : 'now-ui-icons media-1_button-pause'
                        ]"
                      ></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip
                    content="Delete"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="confirmDeleteCampaign(scope.row)"
                      type="danger"
                      size="sm"
                      icon
                      :disabled="
                        !hasRole('delete') ||
                          scope.row.status === 'in_progress' ||
                          scope.row.status === 'completed'
                      "
                    >
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="campaign.totalPages"
          :perPage="campaign.perPage"
          v-model="currentPage"
          @input="changePage"
        ></n-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  Table,
  TableColumn,
  MessageBox,
  Loading,
  Select,
  Option
} from "element-ui";
import moment from "moment";
import { Pagination } from "src/components";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Helper from "@/util/helper";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option
  },
  created: function() {
    userRolesCheck("read", "campaign_rule");
    this.loadData();
  },
  filters: {
    format(value) {
      return value.replaceAll("_", " ");
    }
  },
  data: function() {
    return {
      loading: true,
      currentPage: 0,
      statusList: [
        {
          code: "active",
          label: "Active"
        },
        {
          code: "draft",
          label: "Draft"
        },
        {
          code: "in_progress",
          label: "In Progress"
        },
        {
          code: "completed",
          label: "Completed"
        },
        {
          code: "failed",
          label: "Failed"
        },
      ]
    };
  },
  computed: {
    ...mapState("campaign", ["campaign", "filter"])
  },
  methods: {
    ...mapActions("campaign", [
      "getCampaignList",
      "deleteCampaign",
      "toggleCampaignStatus"
    ]),
    ...mapMutations("campaign", ["CLEAR_FILTER"]),
    hasRole(role) {
      return findUserRole(role, "campaign_rule");
    },
    btnClearFilterClick: function() {
      this.CLEAR_FILTER();
      this.loadData();
    },
    handleEdit(index, data) {
      const subject = this.parseSubject(data.langs);
      this.$router.push({
        name: "Campaign Email - Edit Email",
        params: { id: data.id, slug: Helper.convertToSlug(subject) }
      });
    },
    parseSubject: function(langs) {
      if (langs.length === 1) {
        return langs[0].subject;
      }

      return langs.find(function(el) {
        return el.lang === "EN";
      })?.subject;
    },
    parseDateTime: function(dateTime) {
      return moment(dateTime).format("D MMM YYYY, hh:mm A");
    },
    loadData() {
      this.getCampaignList({ page: 1 })
        .then(response => {
          this.loading = false;
          this.currentPage = this.campaign.currentPage;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    confirmToggleStatus(index, data) {
      let message;
      let buttonText;

      if (data.status === "active") {
        message = `Set campaign "${this.parseSubject(data.langs)}" to draft?`;
        buttonText = "Save to Draft";
      } else {
        message = `Activate campaign "${this.parseSubject(data.langs)}"?`;
        buttonText = "Activate";
      }

      MessageBox.confirm(message, "Confirmation", {
        confirmButtonText: buttonText,
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.toggleCampaignStatus({ data: data, index: index })
            .then(response => {
              loadingInstance.close();
            })
            .catch(error => {
              console.log(error);

              loadingInstance.close();
              this.$notify({
                message: `${buttonText} failed`,
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    confirmDeleteCampaign(data) {
      MessageBox.confirm(
        `This will permanently delete email campaign "${this.parseSubject(
          data.langs
        )}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteCampaign(data)
            .then(response => {
              loadingInstance.close();
              this.$notify({
                message: "Delete email campaign success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
              });
            })
            .catch(error => {
              loadingInstance.close();
              this.$notify({
                message: "Delete email campaign failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getCampaignList({ page })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    parseUCFirst: function(string) {
      if (string) {
        return (
          string.charAt(0).toUpperCase() + string.slice(1).replaceAll("_", " ")
        );
      }
      return "";
    }
  }
};
</script>
