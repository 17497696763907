<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <div class="row align-items-center">
          <div class="col-md-6">
            <h5>Action for this member</h5>
            <p style="color: grey">
              You can make level and point changes as well as redeem on be half
              of this member
            </p>
          </div>
          <div class="col-md-6">
            <router-link
              :to="{
                name: 'Rewards Catalogue',
                query: { card_number: `${detail.cardNumber}` },
              }"
            >
              <n-button type="info" class="pull-right ml-3">REDEEM</n-button>
            </router-link>
            <router-link
              :to="{
                name: 'Level Adjustment Create',
                query: { card_number: `${detail.cardNumber}` },
              }"
            >
              <n-button type="info" class="pull-right ml-3 btn-border"
                >ADJUST LEVEL</n-button
              >
            </router-link>
            <router-link
              :to="{
                name: 'Manual Adjustment - New Point Adjustment Form',
                query: {
                  member: JSON.stringify({
                    name: detail.fullName,
                    email: detail.email,
                    card_number: detail.cardNumber,
                  }),
                },
              }"
            >
              <n-button type="info" class="pull-right ml-3 btn-border"
                >ADJUST POINT</n-button
              >
            </router-link>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-4">
      <card style="text-align: center">
        <h1 style="margin-bottom: 0px">
          {{ detail.expirePointThisMonth | number }}
        </h1>
        <span style="color: grey">Expire this month</span>
      </card>
    </div>
    <div class="col-md-4">
      <card style="text-align: center">
        <h1 style="margin-bottom: 0px">
          {{ detail.expirePointNextMonth | number }}
        </h1>
        <span style="color: grey">Expire next month</span>
      </card>
    </div>
    <div class="col-md-4">
      <card style="text-align: center">
        <h1 style="margin-bottom: 0px">
          {{ detail.expirePointNextTwoMonth | number }}
        </h1>
        <span style="color: grey">Expire next two months</span>
      </card>
    </div>
    <div class="col-md-6">
      <card style="height: 750px">
        <div slot="header">
          <h4 class="card-title">Personal Information</h4>
        </div>
        <div class="row">
          <div class="col-md-6">Full Name</div>
          <div class="col-md-6 text-right">{{ detail.fullName }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Gender</div>
          <div class="col-md-6 text-right">{{ detail.gender }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Birthday</div>
          <div class="col-md-6 text-right">{{ detail.birthday }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Nationality</div>
          <div class="col-md-6 text-right">{{ detail.nationality }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Maritial Status</div>
          <div class="col-md-6 text-right">{{ detail.maritial }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">ID Card</div>
          <div class="col-md-6 text-right">{{ detail.idCard }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Passport</div>
          <div class="col-md-6 text-right">{{ detail.passport }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Language</div>
          <div class="col-md-6 text-right">{{ detail.language }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-6">
      <card style="height: 780px">
        <div slot="header">
          <h4 class="card-title">Membership</h4>
        </div>
        <div class="row">
          <div class="col-md-6">Total Points</div>
          <div class="col-md-6 text-right">
            {{ detail.totalPoint | number }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Point Validity</div>
          <div class="col-md-6 text-right">
            {{ detail.pointValidity | formatDate }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Card Number</div>
          <div class="col-md-6 text-right">{{ detail.cardNumber }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Level</div>
          <div class="col-md-6 text-right">
            {{ detail.level | capitalized }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Next Level</div>
          <div class="col-md-6 text-right">
            {{ detail.nextLevel | capitalized }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Experience Needed For Next Level</div>
          <div class="col-md-6 text-right">
            {{ detail.experienceNeededToNextLevel | number }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Retention Experience</div>
          <div class="col-md-6 text-right">
            {{ detail.retentionExperience | number }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Night Needed For Next Level</div>
          <div class="col-md-6 text-right">
            {{ detail.nightNeededToNextLevel | number }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Retention Night</div>
          <div class="col-md-6 text-right">
            {{ detail.retentionNight | number }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Time to Achieve</div>
          <div class="col-md-6 text-right">
            {{ detail.waitUntil | formatDateTime }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Member Since</div>
          <div class="col-md-6 text-right">
            {{ detail.memberSince | formatDate }}
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Enrolment Type</div>
          <div class="col-md-6 text-right">{{ detail.enrolmentType }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Recommended By</div>
          <div class="col-md-6 text-right">{{ detail.recommendedBy }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-6">
      <card style="height: 550px">
        <div slot="header">
          <h4 class="card-title">Contact Information</h4>
        </div>
        <div class="row">
          <div class="col-md-6">Email</div>
          <div class="col-md-6 text-right">{{ detail.email }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Mobile Phone</div>
          <div class="col-md-6 text-right">{{ detail.mobile }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Home Phone</div>
          <div class="col-md-6 text-right">{{ detail.homePhone }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            Address
            <br />
            <p class="mt-3">{{ detail.address }}</p>
          </div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">Postal Code</div>
          <div class="col-md-6 text-right">{{ detail.postalCode }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Country</div>
          <div class="col-md-6 text-right">{{ detail.country }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-6">
      <card style="height: 550px">
        <div slot="header">
          <h4 class="card-title">Professional Information</h4>
        </div>
        <div class="row">
          <div class="col-md-6">Company</div>
          <div class="col-md-6 text-right">{{ detail.company }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Office Phone</div>
          <div class="col-md-6 text-right">{{ detail.officePhone }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Fax</div>
          <div class="col-md-6 text-right">{{ detail.fax }}</div>
          <div class="col-md-12">
            <hr />
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-12">
      <transaction-history></transaction-history>
    </div>
    <div class="col-md-12">
      <membership-level-history></membership-level-history>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { userRolesCheck } from "@/util/userRoles";

import TransactionHistory from "./TransactionHistory";
import MembershipLevelHistory from "./MembershipLevelHistory";

export default {
  components: {
    "transaction-history": TransactionHistory,
    "membership-level-history": MembershipLevelHistory,
  },
  created: function () {
    userRolesCheck("read", "membership");
    const cardNumber = this.$route.params.cardNumber;
    const name = this.$route.params.slug.split("-").join(" ");
    this.getDetail({ cardNumber, name });
  },
  computed: {
    ...mapState("members", ["detail"]),
  },
  methods: {
    ...mapActions("members", ["getDetail"]),
  },
  filters: {
    formatDate(value) {
      return moment(String(value)).format("DD MMM YYYY, HH:mm");
    },
  },
};
</script>
<style scoped></style>
