<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <card>
        <div slot="header" class="row">
          <div class="col-md-6">
            <h4 class="card-title" style="margin: 0px;">eVoucher Detail</h4>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <el-select
                class="select-info pull-right"
                style="width: 185px"
                size="small"
                placeholder="Select language"
                v-model="form.lang"
                :filterable="true"
                :disabled="isDisableSelectLanguage"
              >
                <el-option
                  v-for="option in languages"
                  class="select-info"
                  :value="option.code"
                  :label="option.country"
                  :key="option.code"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div
              class="row justify-content-center"
              style="padding-bottom: 5px;"
            >
              <label class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-10">
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    type="text"
                    :error="failed ? 'Name is required' : null"
                    :hasSuccess="passed"
                    v-model="form.name"
                    @change="onChangeName"
                  ></fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row justify-content-center">
              <label class="col-sm-2 col-form-label">Category</label>
              <div class="col-sm-10">
                <div class="form-group">
                  <ValidationProvider
                    name="category"
                    rules="required"
                    v-slot="{ failed }"
                  >
                    <el-select
                      name="category"
                      class="select-info"
                      size="large"
                      placeholder="Select category"
                      v-model="voucher.category_id"
                    >
                      <el-option
                        v-for="option in categoryAll"
                        class="select-info"
                        :value="option.category_id"
                        :label="option.name"
                        :key="option.category_id"
                      ></el-option>
                    </el-select>
                    <span
                      class="text-danger invalid-feedback error-padding-bottom"
                      style="display: block;"
                      >{{ failed ? `Category is required` : null }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <label class="col-sm-2 col-form-label">Property</label>
              <div class="col-sm-10">
                <div class="form-group">
                  <ValidationProvider
                    name="property"
                    rules="required"
                    v-slot="{ failed }"
                  >
                    <el-select
                      name="property"
                      class="select-info"
                      size="large"
                      placeholder="Select properties"
                      multiple
                      v-model="voucher.property"
                    >
                      <el-option
                        v-for="option in propertyAll"
                        class="select-info"
                        :value="option.code"
                        :label="option.langs[0].name"
                        :key="option.code"
                      ></el-option>
                    </el-select>
                    <span
                      class="text-danger invalid-feedback error-padding-bottom"
                      style="display: block;"
                      >{{ failed ? `Property is required` : null }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="pull-right pr-2">
              <div class="picture-container">
                <ValidationProvider
                  ref="imageUpload"
                  rules="required"
                  v-slot="{ failed }"
                >
                  <el-upload
                    class="picture"
                    list-type="picture-card"
                    action="#"
                    :on-change="handlePreview"
                    :auto-upload="false"
                    :show-file-list="false"
                  >
                    <el-image
                      style="width: 148px; height: 148px;"
                      fit="contain"
                      v-if="imageUrl"
                      :src="imageUrl"
                    >
                    </el-image>
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>

                  <label class="col-form-label text-center" style="width: 100%">
                    Max. Image 500kb<br />
                    350 x 350px
                  </label>
                  <span
                    class="text-danger invalid-feedback error-padding-bottom text-center"
                    style="display: block;"
                    >{{ failed ? `Image is required` : null }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </card>
      <card>
        <h4 slot="header" class="card-title">Information</h4>
        <div>
          <div class="row">
            <div class="col-md-10 pb-3">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-sm-12 col-form-label" style="padding: 0px;"
                    >e-Voucher Type</label
                  >
                  <div class="form-group">
                    <ValidationProvider
                      name="voucherType"
                      rules="required"
                      v-slot="{ failed }"
                    >
                      <el-select
                        class="select-info"
                        size="large"
                        placeholder="Select voucher type"
                        v-model="voucher.type"
                      >
                        <el-option
                          v-for="option in voucherTypes"
                          class="select-info"
                          :value="option.code"
                          :label="option.label"
                          :key="option.label"
                        ></el-option>
                      </el-select>
                      <span
                        class="text-danger invalid-feedback"
                        style="display: block;"
                        >{{ failed ? `Voucher type is required` : null }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input
                        type="text"
                        label="SKU (Optional)"
                        v-model="voucher.sku"
                      ></fg-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <ValidationProvider
                    name="itemValue"
                    rules="required|max_value:999999999"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="number"
                      label="Item Value"
                      :error="
                        failed
                          ? 'Item value is required and less then equal 999999999'
                          : null
                      "
                      :hasSuccess="passed"
                      v-model="voucher.value"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="col-md-10 pb-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-12">
                       <ValidationProvider
                    name="weight"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                      <fg-input
                        type="text"
                        :error="failed ? 'Position is required' : null"
                        :hasSuccess="passed"
                        label="Position"
                        v-model="voucher.weight"
                      ></fg-input>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-2 col-form-label">Has Stock</label>
                <div class="col-sm-12">
                  <div class="row">
                    <div style="padding-top: 8px;padding-left: 15px">
                      <el-radio v-model="voucher.limit_quantity" :label="false"
                        >No</el-radio
                      >
                      <el-radio v-model="voucher.limit_quantity" :label="true"
                        >Yes</el-radio
                      >
                    </div>
                    <div style="width: 200px; margin-left: 40px;">
                      <div v-if="voucher.limit_quantity">
                        <ValidationProvider
                          name="quantity"
                          rules="required"
                          v-slot="{ passed, failed }"
                        >
                          <el-input-number
                            :error="failed ? 'Quantity is required' : null"
                            :hasSuccess="passed"
                            v-model="voucher.quantity"
                          ></el-input-number>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
      <card>
        <h4 slot="header" class="card-title">Term &amp; Conditions</h4>
        <div>
          <div class="row justify-content-center">
            <div class="col-md-10 pb-3">
              <ValidationProvider
                name="tnc"
                rules="required"
                v-slot="{ failed }"
              >
                <vue-editor v-model="form.tnc" @text-change="onChangeTNC"></vue-editor>
                <span
                  class="text-danger invalid-feedback error-padding-bottom"
                  style="display: block;"
                  >{{ failed ? `Term and condition is required` : null }}</span
                >
              </ValidationProvider>
            </div>
          </div>
        </div>
      </card>
      <card>
        <h4 slot="header" class="card-title">Date</h4>
        <div>
          <div class="row">
            <div class="col-md-12 pb-3">
              <div class="row">
                <label class="col-sm-2 col-form-label">e-Voucher Expiry</label>
                <div class="col-sm-8">
                  <div class="row">
                    <div style="padding-top: 8px;">
                      <el-radio v-model="voucher.expiry_type" label="none"
                        >No Expiry</el-radio
                      >
                      <el-radio v-model="voucher.expiry_type" label="number"
                        >Days</el-radio
                      >
                      <el-radio v-model="voucher.expiry_type" label="expiry_at"
                        >Specific Date</el-radio
                      >
                    </div>
                    <div style="width: 200px; margin-left: 40px;">
                      <div v-if="voucher.expiry_type == 'number'">
                        <ValidationProvider
                          name="day"
                          rules="required"
                          v-slot="{ passed, failed }"
                        >
                          <el-input-number
                            :error="failed ? 'Day is required' : null"
                            :hasSuccess="passed"
                            v-model="voucher.expiry_number"
                            @change="onChangeExpiryByDay"
                          ></el-input-number>
                        </ValidationProvider>
                      </div>
                      <div v-if="voucher.expiry_type == 'expiry_at'">
                        <ValidationProvider
                          name="dateExpiry"
                          rules="required"
                          v-slot="{ failed }"
                        >
                          <el-date-picker
                            v-model="dateExpiry"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="Pick a day"
                          >
                          </el-date-picker>
                          <span
                            class="text-danger invalid-feedback error-padding-bottom"
                            style="display: block;"
                            >{{
                              failed ? `Date expiry is required` : null
                            }}</span
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pb-3">
              <div class="row">
                <label class="col-sm-2 col-form-label"
                  >e-Voucher Validity</label
                >
                <div class="col-sm-8" style="padding-left: 0px;">
                  <div style="width: 400px;">
                    <el-date-picker
                      v-model="voucherValidity"
                      type="daterange"
                      align="right"
                      start-placeholder="Start Date"
                      end-placeholder="End Date"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pb-3">
              <div class="row">
                <label class="col-sm-2 col-form-label">e-Voucher Status</label>
                <div class="col-sm-8">
                  <div class="row">
                    <div style="padding-top: 8px;">
                      <el-radio v-model="voucher.status" :label="true"
                        >Active</el-radio
                      >
                      <el-radio v-model="voucher.status" :label="false"
                        >Inactive</el-radio
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-md-12">
          <n-button
            @click.native="btnCancelClick"
            type="info"
            class="btn btn-default  btn-border"
            >CANCEL</n-button
          >
          <n-button native-type="submit" class="pull-right" type="info" :disabled="!hasRole()">
            SUBMIT
          </n-button>
          <n-button
            @click.native="btnDeleteClick"
            class="pull-right"
            type="danger"
            v-show="showDelete"
            :disabled="!hasRole('delete')">
            DELETE
          </n-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import {
  Select,
  Option,
  Loading,
  MessageBox,
  Upload,
  Radio,
  DatePicker,
  InputNumber,
  Image
} from "element-ui";

import { extend } from "vee-validate";
import { required, max_value } from "vee-validate/dist/rules";
import { VueEditor } from "vue2-editor";
import {findUserRole} from "@/util/userRoles";

extend("required", required);
extend("max_value", max_value);

export default {
  props: {
    submit: Function,
    delete: Function,
    role: String
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload,
    [Radio.name]: Radio,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [InputNumber.name]: InputNumber,
    VueEditor
  },
  data() {
    return {
      imageUrl: "",
      dateExpiry: null,
      showDelete: false,
      voucherValidity: []
    };
  },
  created: function() {
    this.RESET_VOUCHER();
    this.getLanguages();
    this.getVoucherTypes();
    this.getPropertyAll();
    this.getCategoryAll(this.form.lang);
    const id = this.$route.params.id;

    if (typeof id !== "undefined") {
      this.showDelete = true;

      let loadingInstance = Loading.service({
        fullscreen: true
      });
      const module = `voucher with id: ${id}, name: ${this.$route.params.slug}`;
      this.getVoucher({ id, module })
        .then(response => {
          if (response.data.data.image) {
            this.imageUrl = response.data.data.image;
            this.$refs.imageUpload.validate(this.imageUrl);
          }

          if (this.voucher.start_date && this.voucher.end_date) {
            this.voucherValidity = [
              this.voucher.start_date,
              this.voucher.end_date
            ];
          }

          switch (this.voucher.expiry_type) {
            case "none":
              this.voucherExpiryType = "0";
              break;
            case "number":
              this.voucherExpiryType = "1";
              break;
            case "expiry_at":
              this.voucherExpiryType = "2";
              break;
            default:
              this.voucherExpiryType = "0";
              break;
          }

          if (this.voucher.expiry_at) {
            this.dateExpiry = this.voucher.expiry_at;
          }

          loadingInstance.close();
        })
        .catch(error => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("references", ["languages", "voucherTypes"]),
    ...mapState("properties", ["propertyAll"]),
    ...mapState("categories", ["categoryAll"]),
    ...mapState("vouchers", ["voucher", "form"]),
    ...mapGetters("vouchers", ["isDisableSelectLanguage"]),
    activeLang: function() {
      return this.form.lang;
    }
  },
  watch: {
    dateExpiry: function(newDate, oldDate) {
      this.SET_EXPIRY_AT_BY_DATE(newDate);
    },
    voucherValidity: function(newRange, oldRange) {
      if (newRange.length > 0) {
        this.SET_VALIDITY_START_END_DATE(newRange);
      }
    },
    activeLang: function(newLang, oldLang) {
      this.SET_NAME_TNC_LANG(oldLang);
      this.GET_NAME_TNC_LANG();
    }
  },
  methods: {
    ...mapActions("references", ["getLanguages", "getVoucherTypes"]),
    ...mapActions("properties", ["getPropertyAll"]),
    ...mapActions("categories", ["getCategoryAll"]),
    ...mapActions("vouchers", ["getVoucher"]),
    ...mapMutations("vouchers", [
      "SET_NAME_TNC_LANG",
      "GET_NAME_TNC_LANG",
      "SET_EXPIRY_AT_BY_DAY",
      "SET_EXPIRY_AT_BY_DATE",
      "SET_EXPIRY_TYPE",
      "SET_VALIDITY_START_END_DATE",
      "SET_IMAGE",
      "RESET_VOUCHER"
    ]),
    onChangeExpiryByDay(value) {
      const day = parseInt(value);
      if (typeof day == "number" && day) {
        this.SET_EXPIRY_AT_BY_DAY(day);
      }
    },
    onChangeName(event) {
      if (this.form.name.length > 0 && this.form.tnc.length > 0) {
        this.SET_NAME_TNC_LANG(this.form.lang);
      }
    },
    onChangeTNC(event) {
      if (this.form.name.length > 0 && this.form.tnc.length > 0) {
        this.SET_NAME_TNC_LANG(this.form.lang);
      }
    },
    async btnSubmitClick() {
      this.submit();
    },
    async btnDeleteClick() {
      this.delete();
    },
    btnCancelClick() {
      if (
        this.$route.query.referrer !== undefined &&
        this.$route.query.referrer === "voucher-category-list"
      ) {
        this.$router.push({
          name: "Category - Voucher List",
          params: {
            id: this.$route.query.category_id,
            slug: this.$route.query.category_name
          }
        });
      } else {
        this.$router.push({ name: "Vouchers" });
      }
    },
    async handlePreview(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = () => this.SET_IMAGE(reader.result);
      this.imageUrl = URL.createObjectURL(file.raw);
      await this.$refs.imageUpload.validate(this.imageUrl);
    },
    hasRole(role = this.role) {
      return findUserRole(role, "voucher_management");
    }
  }
};
</script>
<style scoped>
.el-select {
  width: 100%;
}
.el-tag {
  border-color: transparent !important;
  margin-top: 15px !important;
}
.el-tag__close {
  color: white !important;
}
.el-input__inner {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.label-padding-top {
  padding-top: 20px;
}
.error-padding-bottom {
  padding-bottom: 10px;
}
.picture {
  width: 175px !important;
  height: 175px !important;
}
.el-upload {
  width: 179px !important;
  height: 179px !important;
}
.el-upload--picture-card {
  line-height: 175px;
}
</style>
