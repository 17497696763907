<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <campaign-information
          style="height: 700px; overflow: scroll;"
        ></campaign-information>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-preview
          style="height: 700px; overflow: scroll;"
        ></campaign-preview>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-recipient
          style="height: 300px; overflow: scroll;"
        ></campaign-recipient>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-schedule
          style="height: 300px; overflow: scroll;"
        ></campaign-schedule>
      </div>
      <div class="mx-3">
        <n-button
          class="mr-2 btn btn-outline-info"
          wide
          @click.native="saveCampaign('draft')"
          v-loading="loading.draft"
          :disabled="loading.draft || loading.save || !hasRole('create') || !(validation.subject && validation.description)"
        >
          DRAFT
        </n-button>
        <n-button
          class="btn btn-info"
          wide
          @click.native="saveCampaign('active')"
          v-loading="loading.save"
          :disabled="loading.draft || loading.save || !hasRole('create') || !(validation.subject && validation.description)"
        >
          SAVE
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignInformation from "@/components/EmailCampaign/Create/CampaignInformation";
import CampaignPreview from "@/components/EmailCampaign/Create/CampaignPreview";
import CampaignRecipient from "@/components/EmailCampaign/Create/CampaignRecipient";
import CampaignSchedule from "@/components/EmailCampaign/Create/CampaignSchedule";
import { mapActions, mapMutations, mapState } from "vuex";
import { MessageBox } from "element-ui";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "CreateEmailCampaign",
  components: {
    "campaign-information": CampaignInformation,
    "campaign-preview": CampaignPreview,
    "campaign-recipient": CampaignRecipient,
    "campaign-schedule": CampaignSchedule
  },
  data() {
    return {
      loading: {
        save: false,
        draft: false
      }
    };
  },
  computed: {
    ...mapState("emailCampaign", ["emailCampaign", "validation"])
  },
  created() {
    userRolesCheck("create", "campaign_rule");
  },
  methods: {
    ...mapActions("emailCampaign", ["createCampaign"]),
    ...mapMutations("emailCampaign", ["SET_IS_ACTIVE"]),
    hasRole(role) {
      return findUserRole(role, "campaign_rule");
    },
    saveCampaign(status) {
      const today = moment().format("YYYY-MM-DD");
      const schedule = moment(this.emailCampaign.scheduled_at).format(
        "YYYY-MM-DD"
      );
      if (moment(schedule).isBefore(moment(today))) {
        MessageBox.alert("Invalid schedule date", "Error", {
          confirmButtonText: "OK"
        });
        return;
      }
      if (status === "active") {
        this.loading.save = true;
      } else {
        this.loading.draft = true;
      }

      this.SET_IS_ACTIVE(status);

      this.createCampaign(status)
        .then(() => {
          Swal.fire({
            icon: "success",
            title:
              status === "active"
                ? "Your campaign has been successfully created, and will be running on designated date."
                : "Your campaign has been saved to draft.",
            text: "Create saved",
            onClose: () => {
              this.$router.push({ name: "Email Campaign List" });
            }
          });
        })
        .catch(() => {
          this.$notify({
            message: "Save campaign failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        })
        .finally(() => {
          this.loading.save = false;
        });
    }
  }
};
</script>

<style scoped></style>
