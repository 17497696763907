<template>
  <card v-loading="loadingDetail">
    <h3 class="card-title">Schedule</h3>
    <div class="row mt-4">
      <div class="col-3">Sending date</div>
      <div class="col-8">
        <fg-input style="width: 100%">
          <el-date-picker
            v-model="emailCampaign.scheduled_at"
            type="datetime"
            placeholder="Select date and time"
            format="dd - MMM - yyyy, hh:mm A"
          >
          </el-date-picker>
        </fg-input>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState } from "vuex";
import { DatePicker, TimeSelect } from "element-ui";

export default {
  name: "CampaignSchedule",
  props: {
    loadingDetail: Boolean
  },
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: date => {
          return date.getTime() < Date.now();
        }
      }
    };
  },
  computed: {
    ...mapState("emailCampaign", ["emailCampaign"])
  }
};
</script>

<style scoped></style>
