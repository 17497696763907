<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(btnSubmitClick)">
        <card>
          <div slot="header">
            <h4 class="card-title">Basic Point Settings</h4>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Name of this rules</label>
            <div class="col-sm-5">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  v-model="basicPoint.name"
                  type="text"
                  :error="failed ? 'Name is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label label-padding-top"
              >Point by</label
            >
            <div class="col-sm-5">
              <div class="form-group">
                <el-select
                  class="select-info"
                  size="large"
                  placeholder="Select point by"
                  v-model="settings.pointBy"
                >
                  <el-option
                    v-for="option in pointByAll"
                    class="select-info"
                    :value="option.code"
                    :label="option.label"
                    :key="option.code"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label"
              >1 point basic equals to</label
            >
            <div class="col-sm-5">
              <ValidationProvider
                name="oneBonusPointEqual"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  v-model="settings.oneBonusPointEqual"
                  type="number"
                  :error="failed ? '1 point basic equals to is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Nights & Points</label>
            <div class="col-sm-5">
              <div class="form-group">
                <el-select
                  class="select-info"
                  size="large"
                  placeholder="Select night & point"
                  v-model="settings.nightPoint"
                >
                  <el-option
                    v-for="option in nightAndPointAll"
                    class="select-info"
                    :value="option.code"
                    :label="option.label"
                    :key="option.code"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </card>
        <condition-list></condition-list>
        <div class="row">
          <div class="col-md-12">
            <n-button native-type="submit" type="info" :disabled="!hasRole()"
              >SUBMIT</n-button
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Select, Option, Loading } from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ConditionList from "./ConditionList";
import { findUserRole } from "@/util/userRoles";

extend("required", required);

export default {
  props: {
    submit: Function,
    role: String,
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    "condition-list": ConditionList,
  },
  created: function () {
    this.RESET_BASIC_POINT();
    this.getPointByAll({ onlyBasic: 0 });
    this.getCustomFieldAll({ status: 1 });

    const id = this.$route.params.id;
    if (typeof id !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });

      const module = `basic point with id: ${id}, name: ${this.$route.params.slug}`;

      this.getBasicPoint({ id, module })
        .then((response) => {
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("pointBy", ["pointByAll"]),
    ...mapState("basicPoint", ["settings", "basicPoint", "nightAndPointAll"]),
  },
  methods: {
    ...mapActions("customFields", ["getCustomFieldAll"]),
    ...mapActions("pointBy", ["getPointByAll"]),
    ...mapActions("basicPoint", ["saveBasicPoint", "getBasicPoint"]),
    ...mapMutations("basicPoint", ["RESET_BASIC_POINT"]),
    hasRole() {
      return findUserRole(this.role, "point_earning_rules");
    },
    btnSubmitClick() {
      if (this.basicPoint.conditions.length === 0) {
        this.$notify({
          message: "Conditions cannot be empty",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.submit();
      }
    },
  },
};
</script>
<style scoped>
/* .el-select {
  width: 100%;
  height: 50px;
}
.label-padding-top {
  padding-top: 20px;
} */
</style>
