<template>
  <card v-loading="loading || loadingDetail">
    <div class="row">
      <div class="col-8 form-group">
        <h3 class="card-title">Campaign Information</h3>
      </div>
      <div class="col-4">
        <div class="form-group">
          <el-select
            v-model="currentLang"
            class="select-info"
            placeholder="Select..."
            size="large"
            :disabled="!(validation.subject && validation.description)"
          >
            <el-option
              v-for="(option, i) in languages"
              :key="i"
              :label="option.country"
              :value="option.code"
              class="select-info"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="col-12 mb-2">
        <el-alert type="info" :closable="false" effect="dark" show-icon>
          You can add pre defined variable in the email content by inserting
          this tags : {title} , {first_name} , {last_name}, {level}
        </el-alert>
      </div>
      <div class="col-3 col-form-label">Campaign Name</div>
      <div class="col-9">
        <ValidationProvider
          name="campaign_name"
          rules="required"
          v-slot="{ passed, failed }"
          ref="campaignNameInput"
        >
          <fg-input
            v-model="emailCampaign.name"
            class="member-input"
            placeholder="Campaign Name"
            :error="failed ? 'Campaign Name field is required' : null"
            :hasSuccess="passed"
            @input="validationCheck"
          ></fg-input>
        </ValidationProvider>
      </div>
      <div class="col-3 col-form-label">Subject</div>
      <div class="col-9">
        <ValidationProvider
          name="subject"
          rules="required"
          v-slot="{ passed, failed }"
          ref="subjectInput"
        >
          <fg-input
            v-model="emailCampaign.langs[currentLangIndex].subject"
            class="member-input"
            placeholder="Subject"
            :error="failed ? 'Subject field is required' : null"
            :hasSuccess="passed"
            @input="validationCheck"
          ></fg-input>
        </ValidationProvider>
      </div>
      <div class="col-3 col-form-label">Preheader</div>
      <div class="col-9">
        <fg-input
          v-model="emailCampaign.langs[currentLangIndex].preheader"
          class="member-input"
          placeholder="Preheader"
        ></fg-input>
      </div>
      <div class="col-12 mt-5">
        <ValidationProvider
          name="subject"
          rules="required"
          v-slot="{ passed, failed }"
          ref="descriptionInput"
        >
          <vue-editor
            v-model="emailCampaign.langs[currentLangIndex].template"
            useCustomImageHandler
            @image-added="handleUploadImage"
            @blur="validationCheck"
          ></vue-editor>
          <div
            v-if="failed"
            class="text-danger invalid-feedback"
            style="display: block;"
          >
            Field is required
          </div>
        </ValidationProvider>
      </div>
    </div>
  </card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { Option, Select, Upload, Alert } from "element-ui";
import { VueEditor } from "vue2-editor";
import { ACCESS_TOKEN, BASE_URL_TADA_AYANA } from "@/config";
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);

export default {
  name: "CampaignInformation",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload,
    [Alert.name]: Alert,
    VueEditor,
    ValidationProvider
  },
  props: {
    loadingDetail: Boolean
  },
  data() {
    return {
      currentLang: "EN",
      form: "",
      loading: true,
      validSubject: false,
      validDescription: false
    };
  },
  computed: {
    ...mapState("emailCampaign", [
      "emailCampaign",
      "currentLangIndex",
      "validation"
    ]),
    ...mapState("references", ["languages"]),
    uploadURL() {
      return `${BASE_URL_TADA_AYANA}/upload`;
    },
    uploadHeader() {
      const token = localStorage.getItem(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`
      };
    }
  },
  created() {
    // this.loading = true;
    this.getLanguages().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions("references", ["getLanguages"]),
    ...mapActions("emailCampaign", ["uploadImage"]),
    ...mapMutations("emailCampaign", [
      "ADD_NEW_LANGS",
      "SET_CURRENT_LANG_INDEX",
      "SET_IMAGE",
      "SET_VALIDATION"
    ]),
    handleUploadImage: function(file, Editor, cursorLocation, resetUploader) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadImage({ data: reader.result, directory: "campaign" })
          .then(result => {
            let url = result.data.data.url;
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch(err => {});
      };
    },
    validationCheck() {
      let validation = this.validation;
      if (this.$refs.subjectInput) {
        this.$refs.subjectInput.validate().then(data => {
          this.validation.subject = data.valid;
          this.SET_VALIDATION(validation);
        });
      }

      if (this.$refs.descriptionInput) {
        this.$refs.descriptionInput.validate().then(data => {
          this.validation.description = data.valid;
          this.SET_VALIDATION(validation);
        });
      }
    }
  },
  watch: {
    currentLang(newValue, oldValue) {
      let temp = this.emailCampaign.langs.findIndex(
        data => data.lang === newValue
      );
      if (temp < 0) {
        this.ADD_NEW_LANGS(newValue);
        this.SET_CURRENT_LANG_INDEX(this.emailCampaign.langs.length - 1);
      } else {
        this.SET_CURRENT_LANG_INDEX(temp);
      }
    }
  }
};
</script>

<style>
.picture .el-upload {
  width: 100%;
}
</style>
