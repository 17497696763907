<template>
  <form-basic-point-settings :submit="submit" role="update"></form-basic-point-settings>
</template>
<script>
import { mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormBasicPointSettings from "./FormBasicPointSettings";
import { userRolesCheck } from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "form-basic-point-settings": FormBasicPointSettings,
  },
  methods: {
    ...mapActions("basicPoint", ["saveBasicPoint"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.saveBasicPoint()
        .then((response) => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Update basic point success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "PointEarningRules" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Update basic point failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    }
  }
};
</script>
<style scoped>
</style>
