<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">
          Voucher(s) in {{ decodeURI(this.$route.params.slug) }}
        </h4>
      </div>
      <div class="row" style="padding-bottom: 20px">
        <div class="col-sm-2" style="padding-top: 10px">
          <fg-input
            placeholder="Search voucher"
            addon-right-icon="fa fa-search"
            v-model="filter.name"
          ></fg-input>
        </div>
        <div class="col-sm-8">
          <el-select
            class="select-info"
            style="width: 45%"
            size="large"
            placeholder="Select Type"
            v-model="filter.type"
          >
            <el-option
              v-for="option in voucherTypes"
              class="select-info"
              :value="option.code"
              :label="option.label"
              :key="option.code"
            ></el-option>
          </el-select>
          <n-button
            @click.native="btnClearFilterClick"
            class="ml-2 btn-border"
            type="info"
            >CLEAR FILTER</n-button
          >
          <n-button @click.native="btnApplyFilterClick" class="ml-2" type="info"
            >APPLY FILTER</n-button
          >
        </div>
      </div>
      <div class="row" v-show="showBulkDelete">
        <hr />
        <div style="width: 100%">
          <n-button
            class="btn btn-info"
            style="float: right; margin-right: 40px"
            type="danger"
            @click.native="handleBulkDelete"
            :disabled="!hasRole('delete')">
            DELETE SELECTED
          </n-button>
        </div>
      </div>
      <div class="row">
        <el-table
          :data="vouchers"
          @selection-change="changeSelection">
          <el-table-column
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column min-width="55" label="Image">
            <template slot-scope="props">
              <el-image
                fit="cover"
                style="width: 100%; height: 100px"
                :src="props.row.image"
                :lazy="true"
              >
                <div slot="placeholder" class="image-slot">
                  Loading
                  <span class="dot">...</span>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column min-width="200" label="Voucher Name">
            <template slot-scope="props">
              <span
                @click="handleRedirect(props.$index, props.row)"
                style="color: #52c1e9; cursor: pointer"
              >
                {{ parseName(props.row.langs) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" align="right" label="Point">
            <template slot-scope="props">
              {{ props.row.value | number }}
            </template>
          </el-table-column>
          <el-table-column min-width="70" align="center" label="Action">
            <template slot-scope="props">
              <el-tooltip content="Delete" :open-delay="300" placement="top">
                <n-button
                  @click.native="handleDelete(props.$index, props.row)"
                  class="remove"
                  type="danger"
                  size="sm"
                  icon
                  :disabled="!hasRole('delete')"
                >
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.totalPages"
          :perPage="pagination.perPage"
          v-model="currentPage"
          @input="changePage"
        ></n-pagination>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  Table,
  TableColumn,
  MessageBox,
  Image,
  Select,
  Option,
  Loading
} from "element-ui";
import { Pagination } from "src/components";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Image.name]: Image,
    [Select.name]: Select,
    [Option.name]: Option,
    [Pagination.name]: Pagination
  },
  created: function() {
    userRolesCheck("read", "voucher_management");
    this.getVoucherTypes();
    this.getVouchers({
      page: 1,
      categoryId: this.$route.params.id,
      module: `voucher list`
    }).then(response => {
      this.currentPage = this.pagination.currentPage;
    });
  },
  data() {
    return {
      currentPage: 0,
      selectedData: [],
      showBulkDelete: false
    };
  },
  computed: {
    ...mapState("vouchers", [
      "vouchers",
      "filter",
      "pagination"
    ]),
    ...mapState("references", ["voucherTypes"])
  },
  filters: {
    formatQuantity: function(value) {
      return value == null ? "-" : value;
    },
    formatStatus: function(value) {
      return value ? "Active" : "Inactive";
    },
    formatProperty: function(value) {
      if (value.length > 1) {
        return "Multiple";
      } else {
        return value.length === 1 ? value[0] : "-";
      }
    }
  },
  methods: {
    ...mapActions("references", ["getVoucherTypes"]),
    ...mapActions("vouchers", [
      "getVouchers",
      "deleteVoucher",
      "deleteVouchers"
    ]),
    ...mapMutations("vouchers", ["CLEAR_FILTER"]),
    hasRole(role) {
      return findUserRole(role, "voucher_management");
    },
    btnApplyFilterClick: function() {
      this.getVouchers({ page: 1, categoryId: this.$route.params.id });
    },
    btnClearFilterClick: function() {
      this.CLEAR_FILTER();
      this.getVouchers({ page: 1, categoryId: this.$route.params.id });
    },
    changeSelection(x) {
      this.selectedData = x;
      this.showBulkDelete = x.length > 0
    },
    parseName: function(langs) {
      console.log(langs);
      return langs.find(function(el) {
        return el.lang === "EN";
      })?.name;
    },
    parseStatusStyle: function(status) {
      if (status) {
        return "pull-right font-weight-bold text-success";
      } else {
        return "pull-right font-weight-bold text-danger";
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.getVouchers({ page, categoryId: this.$route.params.id });
    },
    getSlugFromLangs(langs) {
      if (langs.length) {
        return Helper.convertToSlug(langs[0].name);
      }
      return "no-name";
    },
    handleBulkDelete() {
      MessageBox.confirm(
        `This will permanently delete ${this.selectedData.length} voucher(s). Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        let loadingInstance = Loading.service({
          fullscreen: true
        });
        this.deleteVouchers(this.selectedData)
          .then(response => {
            loadingInstance.close();
            this.$notify({
              message: "Delete voucher success",
              timeout: 5000,
              icon: "now-ui-icons ui-1_bell-53",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success"
            });
          })
          .catch(error => {
            loadingInstance.close();
            this.$notify({
              message:
                "Delete voucher failed.",
              timeout: 5000,
              icon: "now-ui-icons ui-1_bell-53",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger"
            });
          });
      })
        .catch(error => {
          console.log(error);
        });
    },
    handleRedirect(index, voucher) {
      this.$router.push({
        name: "Edit Voucher",
        params: {
          id: voucher.id,
          slug: Helper.convertToSlug(this.parseName(voucher.langs))
        },
        query: {
          referrer: "voucher-category-list",
          category_id: voucher.category_id,
          category_name: this.$route.params.slug }
      });
    },
    handleDelete(index, voucher) {
      MessageBox.confirm(
        `This will permanently delete voucher "${this.parseName(
          voucher.langs
        )}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteVoucher(voucher)
            .then(response => {
              loadingInstance.close();
              this.$notify({
                message: "Delete voucher success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
              });
            })
            .catch(error => {
              loadingInstance.close();
              this.$notify({
                message:
                  "Delete voucher failed.",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
