<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header">
          <h4 class="card-title">Bonus Point Earning Rule List</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <router-link
              :to="{ name: 'Create Bonus Point Settings' }"
              :disabled="!hasRole('create')"
              class="btn pull-right btn-border"
              >ADD BONUS RULE</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table v-loading="loading" :data="bonusPointAll">
              <el-table-column
                min-width="250"
                prop="name"
                label="Name"
              ></el-table-column>
              <el-table-column min-width="180" label="Bonus Type">
                <template scope="scope">
                  {{ scope.row.type | formatBonus }}
                </template>
              </el-table-column>
              <el-table-column min-width="100" align="right" label="Bonus">
                <template scope="scope">
                  {{ scope.row.value | number }}
                </template>
              </el-table-column>
              <el-table-column min-width="200" align="right" label="Max Amount">
                <template scope="scope">
                  {{ scope.row.max_point_per_user | number }}
                </template>
              </el-table-column>
              <el-table-column min-width="300" align="center" label="Schedule">
                <template scope="scope">
                  <span style="color: black"
                    >{{ scope.row.start_date.split("T")[0] }} -
                    {{ scope.row.end_date.split("T")[0] }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column min-width="100" prop="level" label="Status">
                <template scope="scope">
                  <span style="color: black">{{ scope.row.level }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                header-align="center"
                label="Actions"
              >
                <template slot-scope="scope">
                  <div class="text-center table-actions">
                    <el-tooltip
                      content="Edit"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="editBonusPointSettings(scope.row)"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="now-ui-icons ui-2_settings-90"></i>
                      </n-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Delete"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="
                          confirmDeleteBonusPointSettings(scope.row)
                        "
                        type="danger"
                        size="sm"
                        icon
                        :disabled="!hasRole('delete')"
                      >
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Table, TableColumn, Loading, MessageBox } from "element-ui";
import Helper from "@/util/helper";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    hasRole: Function
  },
  created: function () {
    this.getBonusPointAll()
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
    };
  },
  filters: {
    formatBonus(value) {
      if (value === "fixed_amount") {
        return "Fixed Amount";
      } else if (value === "percentage") {
        return "Percentage";
      } else if (value === "multiplier") {
        return "Multiplier";
      }
    },
  },
  computed: {
    ...mapState("bonusPoint", ["bonusPointAll"]),
  },
  methods: {
    ...mapActions("bonusPoint", ["getBonusPointAll", "deleteBonusPoint"]),
    editBonusPointSettings(data) {
      this.$router.push({
        name: "Update Bonus Point Settings",
        params: { id: data.id, slug: Helper.convertToSlug(data.name) },
      });
    },
    confirmDeleteBonusPointSettings(data) {
      MessageBox.confirm(
        `This will permanently delete bonus point setting "${data.name}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true,
          });
          this.deleteBonusPoint(data)
            .then((response) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete bonus point setting success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete bonus point setting failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>
