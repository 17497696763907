<template>
  <card>
    <h4 slot="header" class="card-title">Role Details</h4>
    <div>
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <label class="col-sm-2 col-form-label">Role Name</label>
                <div class="col-sm-10">
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="text"
                      :error="
                        failed ? 'The Required text field is required' : null
                      "
                      :hasSuccess="passed"
                      v-model="role.name"
                      @blur="addName"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row border-bottom">
                <label class="col-sm-12 col-form-label">Module Access</label>
              </div>
              <div v-if="haveModules">
                <module-access-item
                  v-for="module in role.modules"
                  :admin-role="adminRole"
                  :key="module.code"
                  :selectedCode="module.code"
                  :selectedAccess="module.access"
                  :deleteRow="deleteRow"
                ></module-access-item>
              </div>
              <div v-else class="empty-data text-center">No modules</div>
            </div>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-md-12" style="padding-right: 0px;">
              <n-button
                @click.native="addModuleAccess"
                class="pull-right btn-border"
                type="info"
                :disabled="!canAddModule || !hasRole()"
                >ADD MODULE</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ModuleAccessItem from "./ModuleAccessItem";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {findUserRole} from "@/util/userRoles";

extend("required", required);

export default {
  name: "FormRoleDetails",
  props: {
    adminRole: String
  },
  components: {
    "module-access-item": ModuleAccessItem
  },
  data() {
    return {
      name: ""
    };
  },
  computed: {
    ...mapState("roles", ["role"]),
    ...mapGetters("roles", ["haveModules", "canAddModule"])
  },
  methods: {
    ...mapActions("roles", ["addModuleAccess", "updateName"]),
    changeSelectModule(oldCode, newCode) {},
    hasRole() {
      return findUserRole(this.adminRole, "role");
    },
    getKey(object) {
      const keys = Object.keys(object);
      return keys[0];
    },
    deleteRow(code) {
      this.modules = this.modules.filter(item => item.code != code);
    },
    addName(event) {
      this.updateName(event.target.value);
    }
  }
};
</script>
<style scoped>
.border-bottom {
  border-bottom: thin;
  border-bottom-color: grey;
}
.empty-data {
  padding-top: 20px;
  padding-bottom: 10px;
}
</style>
