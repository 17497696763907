<template>
  <div class="row align-items-center border-bottom">
    <div class="col-md-4" style="padding-bottom: 10px;">
      <el-select
        class="select-info"
        size="large"
        placeholder="Select..."
        v-model="code"
        filterable
        @change="changeSelect"
      >
        <el-option
          v-for="option in modules"
          class="select-info"
          :value="option.code"
          :label="option.label"
          :key="option.label"
          :disabled="optionDisabled(option.code)"
        ></el-option>
      </el-select>
    </div>
    <div class="col-md-7 checkbox-radios">
      <checkbox
        value="read"
        :disabled="!hasRole()"
        v-model="tempSelectedAccess"
        inline
        >Read
      </checkbox>
      <checkbox
        value="create"
        :disabled="!hasRole()"
        v-model="tempSelectedAccess"
        inline
        >Create
      </checkbox>
      <checkbox
        value="update"
        :disabled="!hasRole()"
        v-model="tempSelectedAccess"
        inline
        >Update</checkbox>
      <checkbox
        value="delete"
        :disabled="!hasRole()"
        v-model="tempSelectedAccess"
        inline
        >Delete</checkbox>
      <checkbox
        value="approval"
        :disabled="!hasRole()"
        v-model="tempSelectedAccess"
        inline
        >Approval</checkbox>
    </div>
    <div class="col-md-1">
      <a
        href="#"
        @click="deleteModule()"
        class="pull-right text-danger"
        >X</a
      >
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { Select, Option } from "element-ui";
import { Checkbox } from "src/components";
import { findUserRole } from "@/util/userRoles";

export default {
  name: "ModuleAccessItem",
  props: {
    selectedCode: String,
    selectedAccess: Array,
    deleteRow: Function,
    adminRole: String
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    Checkbox
  },
  data() {
    return {
      code: this.selectedCode,
      tempSelectedAccess: this.selectedAccess
    };
  },
  computed: {
    ...mapState("references", ["modules"]),
    ...mapGetters("roles", ["getSelectedCode"])
  },
  watch: {
    tempSelectedAccess: function(value, oldValue) {
      this.CHANGE_ACCESS({
        code: this.$vnode.key,
        access: value
      });
    }
  },
  methods: {
    ...mapActions("roles", [
      "deleteModuleAccess",
      "changeCodeModuleAccess",
      "updateModuleAccess"
    ]),
    ...mapMutations("roles", ["CHANGE_ACCESS"]),
    changeSelect(code) {
      this.changeCodeModuleAccess({
        oldCode: this.selectedCode,
        newCode: code
      });
    },
    hasRole() {
      return findUserRole(this.adminRole, "role");
    },
    optionDisabled(code) {
      return this.getSelectedCode.filter(item => item === code).length > 0;
    },
    checkboxChecked(event, typeAccess) {
      this.updateModuleAccess({
        flag: event.target.checked,
        code: this.$vnode.key,
        typeAccess: typeAccess
      });
    },
    deleteModule() {
      this.deleteModuleAccess({ code: this.$vnode.key });
    }
  }
};
</script>
<style scoped>
.border-bottom {
  border-bottom: thin;
  border-bottom-color: grey;
}
.checkbox-item {
  float: left;
}
.form-check {
  margin-top: 0px;
}
</style>
