<template>
  <form-point-by-revenue :submit="submit" role="create"></form-point-by-revenue>
</template>
<script>
import { mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormPointByRevenue from "./FormPointByRevenue";
import {userRolesCheck} from "@/util/userRoles";
import Swal from "sweetalert2";

export default {
  components: {
    "form-point-by-revenue": FormPointByRevenue,
  },
  created() {
    userRolesCheck("create", "point_earning_rules");
  },
  methods: {
    ...mapActions("pointBy", ["savePointBy"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.savePointBy()
        .then((response) => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Create point by revenue success",
            text: "Create saved",
            onClose: () => {
              this.$router.push({ name: "PointByRevenue" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Create point by revenue failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
  },
};
</script>
<style scoped>
</style>
