<template>
  <div>
    <el-table
      v-loading="false"
      :data="listData"
      :show-header="false"
      :fit="true"
      :cell-style="() => cellStyle"
    >
      <el-table-column label="E-Voucher" prop="title">
        <template slot-scope="props">
          <span>{{ props.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="E-Voucher" prop="value" align="right">
        <template slot-scope="props">
          <span
            ><b>{{ props.row.value }}</b></span
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import moment from "moment";

export default {
  name: "RedeemTransactionCustomer",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    fieldData: Object,
    type: String
  },
  data() {
    return {};
  },
  computed: {
    cellStyle() {
      return "padding-top: 0; padding-bottom: 0;";
    },
    listData() {
      if (this.type === "transaction") {
        return [
          { title: "Point Redeemed", value: this.fieldData.point_redeemed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") },
          { title: "Quantity", value: this.fieldData.quantity },
          { title: "Status", value: this.fieldData.status },
          { title: "Redemption Date", value: this.formatDate(this.fieldData.redemption_date) }
        ];
      } else if (this.type === "customer") {
        return [
          { title: "Name", value: `${this.fieldData.member.first_name} ${this.fieldData.member.last_name}` },
          { title: "Membership No.", value: this.fieldData.member.card_no },
          { title: "Company", value: this.fieldData.member.company }
        ];
      }
      return [];
    }
  },
  created() {
  },
  methods: {
    formatDate(d) {
      return moment(d).format("D MMM YYYY LT");
    }
  },
};
</script>

<style scoped>
span {
  font-size: 11px;
}
</style>
