<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(btnSubmitClick)">
      <form-role-details :adminRole="adminRole"></form-role-details>
      <form-users :adminRole="adminRole"></form-users>
      <div class="row">
        <div class="col-md-12">
          <n-button
            native-type="submit"
            class="btn"
            type="info"
            :disabled="!hasRole()"
          >
            SUBMIT
          </n-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormRoleDetails from "./FormRoleDetails";
import FormUsers from "./FormUsers";
import { findUserRole } from "@/util/userRoles";

export default {
  props: {
    submit: Function,
    adminRole: String
  },
  components: {
    "form-role-details": FormRoleDetails,
    "form-users": FormUsers
  },
  created: function() {
    this.resetRole();
    this.getUsers();
    this.getModules();

    const id = this.$route.params.id;
    if (typeof id !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true
      });

      const module = `role with id: ${id}, name: ${this.$route.params.slug}`;

      this.getRole({
        id: id,
        module: module
      })
        .then(response => {
          loadingInstance.close();
        })
        .catch(error => {
          loadingInstance.close();
        });
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("roles", ["role"]),
    ...mapGetters("roles", ["haveUnsetModule"])
  },
  methods: {
    ...mapActions("references", ["getModules"]),
    ...mapActions("users", ["getUsers"]),
    ...mapActions("roles", ["saveRole", "getRole", "resetRole"]),
    hasRole() {
      return findUserRole(this.adminRole, "role");
    },
    btnSubmitClick() {
      if (this.role.modules.length === 0 || this.haveUnsetModule) {
        this.$notify({
          message: "Module access cannot be empty",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger"
        });
      } else if (this.role.users.length === 0) {
        this.$notify({
          message: "Users cannot be empty",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger"
        });
      } else {
        this.submit();
      }
    }
  }
};
</script>
<style scoped>
.el-select {
  width: 100%;
}
.label-padding-top {
  padding-top: 20px;
}
.bootstrap-switch {
  margin-bottom: 0px;
}
</style>
