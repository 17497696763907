<template>
  <card v-loading="loadingDetail">
    <h3 class="card-title">Recipient</h3>
    <div class="row">
      <div class="col-3 form-check">
        <span class="form-check-label">Send email to</span>
      </div>
      <div class="col-3">
        <n-radio v-model="emailCampaign.recipient.type" label="all">
          All members
        </n-radio>
      </div>
      <div class="col-3">
        <n-radio v-model="emailCampaign.recipient.type" label="by_criteria">
          Specific members
        </n-radio>
      </div>
      <div class="col-3">
        <n-radio v-model="emailCampaign.recipient.type" label="member_list">
          List members
        </n-radio>
      </div>
      <div class="col-12"><hr /></div>

      <div class="col-12">
        <div class="row" v-if="emailCampaign.recipient.type === 'all'">
          <div class="col-3"></div>
          <div class="col">Estimated recipient {{ memberCount }} members.</div>
        </div>

        <div class="row" v-if="emailCampaign.recipient.type === 'by_criteria'">
          <div class="col-12 pull-right">
            <n-button
              type="info"
              class="btn-border btn-border-small"
              :disabled="
                emailCampaign.status === 'in_progress' ||
                  emailCampaign.status === 'completed'
              "
              @click.native="addNewCriteria()"
            >
              ADD CRITERIA
            </n-button>
          </div>
          <div
            class="col-12"
            v-for="(evaluator, i) in emailCampaign.recipient.criteria
              .evaluators"
            :key="i"
          >
            <div class="row">
              <div class="col-10">
                <recipient-criteria :index="i"></recipient-criteria>
              </div>
              <div class="col-2">
                <n-button
                  icon
                  size="sm"
                  type="danger"
                  @click.native="deleteCriteria(i)"
                  :disabled="
                    emailCampaign.status === 'in_progress' ||
                      emailCampaign.status === 'completed'
                  "
                >
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="emailCampaign.recipient.type === 'member_list'">
          <div class="col-3 form-check">
            <span class="form-check-label">Upload list file</span>
          </div>
          <div>
            <el-upload
              class="picture"
              :show-file-list="false"
              action="#"
              :on-change="uploadList"
              :auto-upload="false"
              accept="application/csv,text/csv"
            >
              <fg-input
                slot="trigger"
                :value="emailCampaign.recipient.file"
                class="member-input"
                placeholder="Browse..."
                style="width: 500px"
              ></fg-input>
            </el-upload>
          </div>
          <div class="col-3 form-check"></div>
          <div class="col-9">
            <p>
              <label>
                csv files with a size less than 10mb (max 15.000 rows per
                upload)
              </label>
            </p>
            <p>
              <a
                href="/template/campaign_recipients.csv"
                style="color: deepskyblue"
              >
                Download template file
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import CriteriaList from "@/pages/PointEarningRules/CriteriaList";
import Radio from "@/components/Inputs/Radio";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import RecipientCriteria from "@/components/EmailCampaign/Create/RecipientCriteria";
import { Upload } from "element-ui";

export default {
  name: "CampaignRecipient",
  components: {
    [Radio.name]: Radio,
    [Upload.name]: Upload,
    "recipient-criteria": RecipientCriteria
  },
  props: {
    loadingDetail: Boolean
  },
  data() {
    return {};
  },
  created() {
    this.getUserCustomFields({ status: 1 });
    this.getMemberCount();
  },
  mounted() {
    this.RESET_EMAIL_CAMPAIGN();
  },
  computed: {
    ...mapState("basicPoint", ["basicPoint"]),
    ...mapGetters("basicPoint", [
      "showButtonAndAtIndex",
      "disableOptionFieldType",
      "inputFieldType"
    ]),
    ...mapState("emailCampaign", [
      "emailCampaign",
      "currentLangIndex",
      "memberCount"
    ])
  },
  methods: {
    ...mapActions("customFields", ["getUserCustomFields"]),
    ...mapActions("emailCampaign", ["uploadMemberList", "getMemberCount"]),
    ...mapMutations("emailCampaign", [
      "ADD_NEW_CRITERIA",
      "RESET_EMAIL_CAMPAIGN",
      "DELETE_CRITERIA"
    ]),
    async uploadList(file) {
      const formData = new FormData();
      formData.append("file", file.raw);
      await this.uploadMemberList(formData);
    },
    addNewCriteria() {
      this.ADD_NEW_CRITERIA();
      this.$forceUpdate();
    },
    deleteCriteria(index) {
      this.DELETE_CRITERIA(index);
    }
  }
};
</script>

<style scoped></style>
