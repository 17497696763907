<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <campaign-information
          :loadingDetail="loading.getEmailCampaign"
          style="height: 700px; overflow: scroll;"
        ></campaign-information>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-preview
          :loadingDetail="loading.getEmailCampaign"
          style="height: 700px; overflow: scroll;"
        ></campaign-preview>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-recipient
          :loadingDetail="loading.getEmailCampaign"
          style="height: 300px; overflow: scroll;"
        ></campaign-recipient>
      </div>
      <div class="col-xs-12 col-md-6">
        <campaign-schedule
          :loadingDetail="loading.getEmailCampaign"
          style="height: 300px; overflow: scroll;"
        ></campaign-schedule>
      </div>
      <div class="mx-3">
        <n-button
          class="mr-2 btn btn-outline-info"
          wide
          @click.native="saveCampaign('draft')"
          v-loading="loading.draft"
          :disabled="
            loading.draft ||
              loading.save ||
              !hasRole('update') ||
              loading.getEmailCampaign ||
              emailCampaign.status === 'in_progress' ||
              emailCampaign.status === 'completed'
          "
        >
          DRAFT
        </n-button>
        <n-button
          class="btn btn-info"
          wide
          @click.native="saveCampaign('active')"
          v-loading="loading.save"
          :disabled="
            loading.draft ||
              loading.save ||
              !hasRole('update') ||
              loading.getEmailCampaign ||
              emailCampaign.status === 'in_progress' ||
              emailCampaign.status === 'completed'
          "
        >
          SAVE
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignInformation from "@/components/EmailCampaign/Create/CampaignInformation";
import CampaignPreview from "@/components/EmailCampaign/Create/CampaignPreview";
import CampaignRecipient from "@/components/EmailCampaign/Create/CampaignRecipient";
import CampaignSchedule from "@/components/EmailCampaign/Create/CampaignSchedule";
import {findUserRole} from "@/util/userRoles";
import {mapActions, mapMutations, mapState} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "EditEmailCampaign",
  components: {
    "campaign-information": CampaignInformation,
    "campaign-preview": CampaignPreview,
    "campaign-recipient": CampaignRecipient,
    "campaign-schedule": CampaignSchedule
  },
  data() {
    return {
      loading: {
        save: false,
        draft: false,
        getEmailCampaign: true
      }
    };
  },
  computed: {
    ...mapState("customFields", ["customFieldAll"]),
    ...mapState("emailCampaign", ["emailCampaign"]),
    campaignId() {
      return this.$route.params.id;
    }
  },
  created() {
    const module = `email campaign with id: ${this.campaignId}, name: ${this.$route.params.slug}`;

    this.getEmailCampaign({ id: this.campaignId, module: module }).finally(
      () => {
        this.loading.getEmailCampaign = false;
      }
    );
  },
  methods: {
    ...mapActions("emailCampaign", ["updateCampaign", "getEmailCampaign"]),
    ...mapMutations("emailCampaign", ["SET_IS_ACTIVE"]),
    hasRole(role) {
      return findUserRole(role, "campaign_rule");
    },
    saveCampaign(status) {
      if (status === "active") {
        this.loading.save = true;
      } else {
        this.loading.draft = true;
      }

      this.SET_IS_ACTIVE(status);

      this.updateCampaign({
        status: status,
        id: this.campaignId,
        criteria: this.customFieldAll
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title:
              status === "active"
                ? "Your campaign has been successfully created, and will be running on designated date."
                : "Your campaign has been saved to draft.",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "Email Campaign List" });
            }
          });
        })
        .catch(() => {
          this.$notify({
            message: "Update campaign failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        })
        .finally(() => {
          this.loading.save = false;
        });
    }
  }
};
</script>

<style scoped></style>
