<template>
  <div style="margin-top: 0px;">
    <criteria-app
      v-for="(evaluator, index) in evaluators"
      :key="index"
      :evaluator="evaluator"
      :indexRow="indexRow"
      :disableOptionFieldType="disableOptionFieldType"
      :inputFieldType="inputFieldType"
      :deleteAnd="deleteAnd"
      :updateEvaluatorValue="updateEvaluatorValue"
      :deleteEvaluatorValue="deleteEvaluatorValue"
      :changeEvaluator="changeEvaluator"
      :addEvaluatorTagValue="addEvaluatorTagValue"
    ></criteria-app>
  </div>
</template>
<script>
import Criteria from "./Criteria";
export default {
  props: {
    evaluators: Array,
    indexRow: Number,
    disableOptionFieldType: Function,
    inputFieldType: Function,
    deleteAnd: Function,
    updateEvaluatorValue: Function,
    deleteEvaluatorValue: Function,
    changeEvaluator: Function,
    addEvaluatorTagValue: Function,
  },
  components: {
    "criteria-app": Criteria,
  },
};
</script>
<style scoped>
</style>