<template>
  <div>
    <img
      class="image"
      :src="imageUrl"
    />
    <p class="voucher-title">
      <strong>{{name}}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "RedeemEVoucher",
  props: {
    imageUrl: String,
    name: String
  },
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped>
.image {
  width: 100%;
  height: auto !important;
}
.voucher-title {
  word-break: break-word !important;
}
</style>
