<template>
  <form-voucher :submit="submit" role="create"></form-voucher>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormVoucher from "./FormVoucher";
import {userRolesCheck} from "@/util/userRoles";
import Swal from "sweetalert2";
import Helper from "@/util/helper";

export default {
  components: {
    "form-voucher": FormVoucher
  },
  created() {
    userRolesCheck("create", "voucher_management");
  },
  methods: {
    ...mapActions("vouchers", ["saveVoucher"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.saveVoucher()
        .then(response => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Create voucher success",
            text: "Create saved",
            onClose: () => {
              if (
                this.$route.query.referrer !== undefined &&
                this.$route.query.referrer === "voucher-category-list"
              ) {
                this.$router.push({
                  name: "Category - Voucher List",
                  params: {
                    id: this.$route.query.category_id,
                    slug: this.$route.query.category_name
                  }
                });
              } else {
                this.$router.push({ name: "Vouchers" });
              }
            }
          });
        })
        .catch(error => {
          loadingInstance.close();
          this.$notify({
            message: "Create voucher failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  }
};
</script>
<style scoped></style>
