<template>
  <div>
    <card v-loading="loading.detail">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-11">
          <div slot="header">
            <h4 class="card-title" v-html="voucherName" />
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 mb-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <img :src="voucher.image" alt="Image" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <el-table
                    v-loading="false"
                    :data="tableData"
                    :show-header="false"
                    :fit="true"
                    :cell-style="{
                      paddingTop: 10,
                      paddingBottom: 0,
                      fontSize: '12px'
                    }"
                  >
                    <el-table-column label="E-Voucher" prop="title">
                      <template slot-scope="props">
                        <span>{{ props.row.title }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="E-Voucher"
                      prop="value"
                      align="right"
                    >
                      <template slot-scope="props">
                        <span>
                          <b>{{ props.row.value }}</b>
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div>
                <h4 class="mt-0">Terms & Condition</h4>
                <div v-html="voucherTNC" class="containerTerms"></div>
                <div class="row" v-if="!showSuccess.show">
                  <label
                    class="col-sm-4 col-md-2 col-lg-2 col-form-label px-0 text-center"
                    >Quantity</label
                  >
                  <div class="col-sm-8 col-md-10 col-lg-10">
                    <fg-input
                      style="width: 90%; margin-bottom: 20px"
                      type="number"
                      v-model="form.quantity"
                      placeholder="Input Quantity"
                    ></fg-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>

    <card>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-11" v-if="!showSuccess.show">
          <div slot="header">
            <h4 class="card-title">Buying This E-Voucher For</h4>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <el-row align="center">
                <el-col :span="6" class="col-form-label">
                  <label>Member Name</label>
                </el-col>
                <el-col :span="18">
                  <div class="form-group">
                    <el-select
                      v-model="form.memberName"
                      filterable
                      remote
                      placeholder="Member Name"
                      :remote-method="filterMember"
                      style="display: block"
                      class="select-info"
                    >
                      <el-option
                        v-for="(member, i) in dataMember"
                        :key="i"
                        :label="
                          (member.first_name ? member.first_name : '') +
                            ' ' +
                            (member.last_name ? member.last_name : '').trim()
                        "
                        :value="
                          (member.first_name ? member.first_name : '') +
                            ' ' +
                            (member.last_name ? member.last_name : '').trim()
                        "
                        @click.native="autoCompleteMember(member)"
                      >
                        <span>
                          {{
                            (member.first_name ? member.first_name : "") +
                              " " +
                              (member.last_name ? member.last_name : "").trim()
                          }}
                        </span>
                        <br />
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row align="center">
                <el-col :span="6" class="col-form-label">
                  <label>Membership No</label>
                </el-col>
                <el-col :span="18">
                  <fg-input
                    v-model="selectedMember.card_number"
                    class="member-input"
                    disabled
                    placeholder="Membership No"
                  ></fg-input>
                </el-col>
              </el-row>
              <el-row align="center">
                <el-col :span="6" class="col-form-label">
                  <label>Email Address</label>
                </el-col>
                <el-col :span="18">
                  <fg-input
                    v-model="selectedMember.email"
                    class="member-input"
                    disabled
                    placeholder="Email Address"
                  ></fg-input>
                </el-col>
              </el-row>
            </div>
            <div class="col-sm-6 d-flex align-items-center flex-column">
              <p class="text-center">
                You are about to buy this e-voucher on be half of this member,
                to continue click "BUY NOW" and point will be deducted.
              </p>
              <n-button
                class="ml-2"
                type="info"
                style="min-width: 150px; width: 250px"
                @click.native="submitForm"
                :disabled="!hasRole('create')"
                >BUY NOW</n-button
              >
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-8 col-lg-6 d-flex flex-column align-items-center my-4"
          v-if="showSuccess.show"
        >
          <div
            class="d-flex justify-content-center align-item-center"
            style="
              border-radius: 50%;
              border: 1px solid #52c0e9;
              height: 90px;
              width: 90px;
            "
          >
            <i
              class="el-icon-check p-0 m-auto"
              style="font-size: 60px; color: #52c0e9"
            ></i>
          </div>

          <div slot="header mb-1">
            <h4 class="card-title text-center" style="font-weight: bold">
              Redeem Success!
            </h4>
          </div>
          <div>
            <h4 class="card-title text-center">
              {{ showSuccess.data.OrderItems[0].itemName }}
            </h4>
          </div>
          <div
            v-for="order in showSuccess.data.OrderVendors[0]
              .integrationResponse"
            :key="order.egift_code"
          >
            <barcode
              v-if="Object.keys(showSuccess.data).length > 0"
              v-bind:value="order.egift_code"
            ></barcode>
          </div>

          <el-table
            v-loading="false"
            :data="tableSuccess"
            :show-header="false"
            :fit="true"
            :cell-style="{
              paddingTop: 10,
              paddingBottom: 0,
              fontSize: '12px'
            }"
          >
            <el-table-column label="E-Voucher" prop="title">
              <template slot-scope="props">
                <span>{{ props.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label="E-Voucher" prop="value" align="right">
              <template slot-scope="props">
                <span>
                  <b>{{ props.row.value }}</b>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Autocomplete,
  Row,
  Col,
  Select,
  Option,
  Loading,
  MessageBox
} from "element-ui";
import { mapActions, mapGetters, mapState } from "vuex";
import VueBarcode from "vue-barcode";
import { required, max_value } from "vee-validate/dist/rules";
import moment from "moment";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
export default {
  name: "RewardsDetail",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Col.name]: Col,
    [Row.name]: Row,
    [Select.name]: Select,
    [Option.name]: Option,
    barcode: VueBarcode
  },
  data() {
    return {
      placeholderData: [
        { title: "Point Redeemed", value: "TBD" },
        { title: "Quantity", value: "TBD" },
        { title: "Status", value: "TBD" }
      ],
      loading: {
        detail: false
      },
      search: "",
      dataMember: [],
      form: {},
      selectedMember: {},
      keywordMember: "",
      showSuccess: {
        show: false,
        data: {}
      }
    };
  },
  computed: {
    ...mapState("rewards", ["voucher"]),
    ...mapState("members", ["list"]),
    ...mapGetters("members", ["detailData"]),
    tableData() {
      return [
        { title: "Status", value: this.voucher.status ? "Active" : "InActive" },
        {
          title: "E-Voucher type",
          value: this.separateNumber(this.voucher.type)
        },
        {
          title: "E-Voucher value",
          value: this.separateNumber(this.voucher.value)
        },

        {
          title: "Expiry",
          value: `${this.separateNumber(this.voucher.expiry_number)} Days`
        }
      ];
    },
    voucherName() {
      return this.voucher.langs.find(function(el) {
        return el.lang === "EN";
      })?.name;
    },
    voucherTNC() {
      return this.voucher.langs.find(function(el) {
        return el.lang === "EN";
      })?.tnc;
    },
    tableSuccess() {
      return Object.keys(this.showSuccess.data).length > 0
        ? [
            {
              title: "Quantity",
              value: this.showSuccess.data.OrderItems[0].quantity
            },
            {
              title: "Expired",
              value:
                this.showSuccess.data.OrderVendors[0].integrationResponse[0]
                  .expired_date == null
                  ? "No expiry"
                  : moment(
                      this.showSuccess.data.OrderVendors[0]
                        .integrationResponse[0].expired_date,
                      "DD/MM/YYYY HH:mm:ss"
                    ).format("DD MMMM YYYY")
            },
            {
              title: "Order Number",
              value: this.showSuccess.data.orderNumber
            }
          ]
        : [];
    }
  },

  methods: {
    ...mapActions("rewards", ["getReward"]),
    ...mapActions("rewards", ["redeemRewards", "getList"]),
    ...mapActions("members", ["getDetail"]),
    loadVoucherDetail() {
      this.loading.detail = true;
      const reward_name = this.$route.params.slug.split("-").join(" ");
      const module = `reward ${this.$route.params.id} ${reward_name}`;
      this.getReward({
        id: this.$route.params.id,
        module,
        pageName: "reward"
      }).finally(() => {
        this.loading.detail = false;
      });
    },
    hasRole(role) {
      return findUserRole(role, "redeem");
    },
    separateNumber(x) {
      if (x == null) return "-";

      return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    filterMember(keyword) {
      keyword = keyword.trim();
      if (keyword.length > 0) {
        this.getList({ search: keyword }).then(data => {
          this.dataMember = data.data;
        });
      }
    },
    autoCompleteMember(data) {
      this.selectedMember = data;
    },
    submitForm() {
      if (this.form.quantity === undefined) {
        MessageBox.alert(
          "Please select quantity before continue.",
          "Information",
          {
            confirmButtonText: "OK"
          }
        );

        return;
      } else if (this.selectedMember.card_number === undefined) {
        MessageBox.alert(
          "Please select member before continue.",
          "Information",
          {
            confirmButtonText: "OK"
          }
        );

        return;
      }

      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.redeemRewards({
        data: {
          items: [
            {
              id: this.voucher.id,
              quantity: parseInt(this.form.quantity)
            }
          ],
          recipient_card_no: this.selectedMember.card_number
        },
        recipient_name: this.form.memberName,
        reward_name: this.voucherName
      })
        .then(result => {
          loadingInstance.close();
          this.showSuccess = {
            show: true,
            data: result.data.data
          };
        })
        .catch(err => {
          loadingInstance.close();
          MessageBox.alert(
            err.response.data.error.message || "Something Wrong",
            "Failed",
            {
              confirmButtonText: "OK"
            }
          );
        });
    }
  },
  created() {
    userRolesCheck("read", "reward_catalog");
    this.loadVoucherDetail();

    const cardNumber = this.$route.query.card_number;

    if (typeof cardNumber !== "undefined") {
      this.getDetail({ cardNumber: cardNumber, name: null })
        .then(response => {
          this.$set(this.form, "memberName", this.detailData.fullName);
          this.$set(
            this.selectedMember,
            "card_number",
            this.detailData.cardNumber
          );
          this.$set(this.selectedMember, "email", this.detailData.email);
        })
        .catch(error => {
          this.$notify({
            message: "Member not found",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.containerTerms {
  p {
    margin-bottom: 1px;
    height: fit-content;
  }
}
</style>
