<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <card id="email-transaction">
          <div slot="header">
            <h4 class="card-title">Mailgun Transaction Email</h4>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Mailgun API Key</label>
            <div class="col-sm-10">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="password"
                  v-model="emailTransactionValue"
                  :error="failed ? 'The Required text field is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label"></label>
            <div class="col-sm-10">
              <n-button
                @click.native="updateEmailKeyValue(emailTransactionKey)"
                class="btn"
                type="info"
                :disabled="!hasRole('update')"
                >SAVE</n-button
              >
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card id="email-blast">
          <div slot="header">
            <h4 class="card-title">Mailgun Email Blast</h4>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Mailgun API Key</label>
            <div class="col-sm-10">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="password"
                  v-model="emailBlastValue"
                  :error="failed ? 'The Required text field is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label"></label>
            <div class="col-sm-10">
              <n-button
                @click.native="updateEmailKeyValue(emailBlastKey)"
                class="btn"
                type="info"
                :disabled="!hasRole('update')"
                >SAVE</n-button
              >
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card>
          <h4 class="card-title">Email Template</h4>
          <el-table v-loading="loading" :data="templates.data">
            <el-table-column min-width="300" prop="template" label="Template">
              <template slot-scope="scope">
                <p><b>{{ scope.row.code | formatCode }}</b></p>
                <h5>{{ getTitle(scope.row.langs, "EN") }}</h5>
                <p>{{ scope.row.description }}</p>
                <a
                  href="javascript:void(0);"
                  style="font-size: 16px; color: #52c1e9"
                  @click="showVariables(scope.row.code)"
                  >Show parameters</a
                >
              </template>
            </el-table-column>
            <el-table-column min-width="100" label="Language">
              <template slot-scope="scope">
                <p>{{ getLangCodes(scope.row.langs) }}</p>
              </template>
            </el-table-column>
            <el-table-column
              min-width="100"
              header-align="center"
              align="center"
              label="Last modified">
              <template slot-scope="scope">
                <p>{{ scope.row.updated | formatDateTime }}</p>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150"
              header-align="right"
              label="Actions"
            >
              <template slot-scope="scope">
                <div class="text-right table-actions">
                  <el-tooltip
                    content="Detail"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="detailEmailTemplate(scope.row)"
                      type="warning"
                      size="sm"
                      icon
                      :disabled="!hasRole('read')"
                    >
                      <i class="now-ui-icons tech_tv"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Edit" :open-delay="300" placement="top">
                    <n-button
                      @click.native="uploadEmailTemplate(scope.row)"
                      size="sm"
                      type="success"
                      icon
                      :disabled="!hasRole('update')"
                    >
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pull-right py-3">
            <n-pagination
              type="info"
              :pageCount="templates.totalPages"
              :perPage="templates.perPage"
              v-model="currentPage"
              @input="changePage"
            ></n-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVariablesVisible" center>
      <el-table v-loading="loading" :data="variables">
        <el-table-column
          min-width="200"
          prop="name"
          label="Name"
        ></el-table-column>
        <el-table-column
          min-width="200"
          prop="code"
          label="Code"
        ></el-table-column>
        <el-table-column min-width="150" header-align="right" label="Copy">
          <template slot-scope="scope">
            <div class="text-right table-actions">
              <el-tooltip content="Copy" :open-delay="300" placement="top">
                <n-button
                  v-clipboard="scope.row.code"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="now-ui-icons files_single-copy-04"></i>
                </n-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="text-center">
        <n-button @click.native="hideVariables" class="btn" type="info"
          >Close</n-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  Table,
  TableColumn,
  Image,
  MessageBox,
  Loading,
  Dialog,
} from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Pagination } from "src/components";
import Swal from "sweetalert2";
import {findUserRole, userRolesCheck} from "@/util/userRoles";
import Helper from "../../../util/helper";
import moment from "moment";

extend("required", required);
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Image.name]: Image,
    [Pagination.name]: Pagination,
    [Dialog.name]: Dialog
  },
  created: function() {
    userRolesCheck("read", "system_configuration");
    this.getTemplates({ page: 1 })
      .then((response) => {
        this.loading = false;
        this.currentPage = this.template.currentPage;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  mounted: function () {
    this.loadEmailKey(this.emailTransactionKey);
    this.loadEmailKey(this.emailBlastKey);
  },
  filters: {
    formatCode(value) {
      return value.replace(/_/g, " ").toUpperCase();
    }
  },
  data: function () {
    return {
      loading: true,
      currentPage: 0,
      emailTransactionValue: "",
      emailBlastValue: "",
      emailTransactionKey: "mailgun_key_for_transaction",
      emailBlastKey: "mailgun_key_for_blast",
      dialogVariablesVisible: false,
    };
  },
  computed: {
    ...mapState("notification", ["templates", "variables"]),
  },
  methods: {
    ...mapActions("notification", [
      "getTemplates",
      "getEmailConfig",
      "updateEmailConfig",
      "getVariables",
    ]),
    hasRole(role){
      return findUserRole(role, "system_configuration");
    },
    getLangCodes: function (langs) {
      return langs.map((item) => item.lang).join(", ");
    },
    getTitle: function (langs, code) {
      const filterLangs = langs.filter((item) => item.lang === code);
      if (filterLangs.length) {
        return filterLangs[0].title;
      }
      return "";
    },
    loadEmailKey: function (key) {
      const selector =
        key === this.emailTransactionKey
          ? "#email-transaction"
          : "#email-blast";
      let loadingInstance = Loading.service({
        target: document.querySelector(selector),
        fullscreen: false,
      });
      this.getEmailConfig(key)
        .then((response) => {
          loadingInstance.close();
          if (key === this.emailTransactionKey) {
            this.emailTransactionValue = response.data.data.value;
          } else {
            this.emailBlastValue = response.data.data.value;
          }
        })
        .catch((error) => {
          loadingInstance.close();
        });
    },
    updateEmailKeyValue: function (key) {
      const value =
        key === this.emailTransactionKey
          ? this.emailTransactionValue
          : this.emailBlastValue;

      const selector =
        key === this.emailTransactionKey
          ? "#email-transaction"
          : "#email-blast";
      let loadingInstance = Loading.service({
        target: document.querySelector(selector),
        fullscreen: false,
      });
      this.updateEmailConfig({ key, value })
        .then((response) => {
          loadingInstance.close();

          this.$notify({
            message: `You have updated your mailgun key`,
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success"
          });
        })
        .catch((error) => {
          loadingInstance.close();

          this.$notify({
            message: "Update mailgun key failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    },
    changePage: function (page) {
      this.currentPage = page;
      this.loading = true;
      this.getTemplates({ page })
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    detailEmailTemplate(data) {
      this.$router.push({
        name: "Email Template Detail",
        params: {
          code: data.code,
          slug: Helper.convertToSlug(data.description),
        },
      });
    },
    uploadEmailTemplate(data) {
      this.$router.push({
        name: "Email Template Upload",
        params: { code: data.code },
      });
    },
    showVariables(code) {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading();
          this.getVariables(code)
            .then((response) => {
              Swal.hideLoading();
              Swal.close();
              this.dialogVariablesVisible = true;
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Couldn't fetch data",
              });
            });
        },
      });
    },
    hideVariables() {
      this.dialogVariablesVisible = false;
    },
  },
};
</script>
<style scoped></style>
