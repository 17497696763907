<template>
  <form-voucher
    :submit="submit"
    :delete="deleteItem"
    role="update"
  ></form-voucher>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Loading, MessageBox } from "element-ui";
import FormVoucher from "./FormVoucher";
import Swal from "sweetalert2";

export default {
  components: {
    "form-voucher": FormVoucher
  },
  computed: {
    ...mapState("vouchers", ["voucher"])
  },
  methods: {
    ...mapActions("vouchers", ["saveVoucher", "deleteVoucher"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true
      });
      this.saveVoucher()
        .then(response => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Update voucher success",
            text: "Update saved",
            onClose: () => {
              if (
                this.$route.query.referrer !== undefined &&
                this.$route.query.referrer === "voucher-category-list"
              ) {
                this.$router.push({
                  name: "Category - Voucher List",
                  params: {
                    id: this.$route.query.category_id,
                    slug: this.$route.query.category_name
                  }
                });
              } else {
                this.$router.push({ name: "Vouchers" });
              }
            }
          });
        })
        .catch(error => {
          loadingInstance.close();
          this.$notify({
            message: "Create voucher failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    },
    deleteItem() {
      MessageBox.confirm(
        `This will permanently delete voucher "${decodeURI(
          this.$route.params.slug
        )}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteVoucher(this.voucher)
            .then(response => {
              loadingInstance.close();
              Swal.fire({
                icon: "success",
                title: "Delete voucher success",
                text: "Voucher deleted",
                onClose: () => {
                  if (
                    this.$route.query.referrer !== undefined &&
                    this.$route.query.referrer === "voucher-category-list"
                  ) {
                    this.$router.push({
                      name: "Category - Voucher List",
                      params: {
                        id: this.$route.query.category_id,
                        slug: this.$route.query.category_name
                      }
                    });
                  } else {
                    this.$router.push({ name: "Vouchers" });
                  }
                }
              });
            })
            .catch(error => {
              loadingInstance.close();
              this.$notify({
                message: "Delete voucher failed.",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped></style>
