<template>
  <div>
    <basic-point-rule :has-role="hasRole"></basic-point-rule>
    <bonus-point-rule :has-role="hasRole"></bonus-point-rule>
    <level-bonus-list :has-role="hasRole"></level-bonus-list>
  </div>
</template>
<script>
import BasicPointRuleList from "./BasicPointSettings/BasicPointRuleList";
import BonusPointRuleList from "./BonusPointSettings/BonusPointRuleList";
import LevelBonusList from "./LevelBonusList";
import {findUserRole, userRolesCheck} from "@/util/userRoles";

export default {
  components: {
    "basic-point-rule": BasicPointRuleList,
    "bonus-point-rule": BonusPointRuleList,
    "level-bonus-list": LevelBonusList,
  },
  created() {
    userRolesCheck("read", "point_earning_rules");
  },
  methods: {
    hasRole(role) {
      return findUserRole(role, "point_earning_rules");
    }
  }
};
</script>
<style scoped>
</style>
