<template>
  <div class="row">
    <div class="col-12">
      <card id="level-bonus">
        <h4 slot="header" class="card-title">Level Bonus</h4>
        <div class="row">
          <el-table stripe v-loading="loading" style="width: 100%;" :data="tableData">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            ></el-table-column>
            <el-table-column :min-width="100" align="center" fixed="right" label="Actions">
              <div slot-scope="props" class="table-actions">
                <n-button
                  @click.native="handleEdit(props.$index, props.row)"
                  :disabled="!hasRole('update')"
                    type="success"
                    size="sm"
                    icon
                >
                  <i class="now-ui-icons ui-2_settings-90"></i>
                </n-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </card>
    </div>
    <el-dialog title="Edit level bonus" :visible.sync="dialogFormVisible">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form-horizontal" @submit.prevent="handleSubmit(lvlSubmitClick)">
          <div class="row">
            <div
              class="col-sm-2 col-form-label"
              style="text-transform:capitalize"
            >{{ levelData.level }}</div>
            <div class="col-sm-4">
              <div class="form-group">
                <el-select v-model="formData.type" class="select-info">
                  <el-option
                    class="select-info"
                    v-for="item in types"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="col-sm-2 col-form-label">Amount</div>
            <div class="col-sm-3">
              <fg-input type="text" v-model="formData.value"></fg-input>
            </div>
          </div>
          <div slot="footer" class="row" style="margin-top:30px">
            <div class="col-md-12 text-center">
              <el-button @click="dialogFormVisible = false">Cancel</el-button>
              <el-button
                type="primary"
                native-type="submit"
                @click="dialogFormVisible = false"
                :disabled="!hasRole('delete')"
              >Confirm</el-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import {
  Table,
  TableColumn,
  Loading,
  Select,
  Option,
  Dialog,
  Button,
} from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  props: {
    hasRole: Function
  },
  created: function () {
    this.getLvlBonus();
  },
  computed: {
    ...mapGetters("levelbonus", ["tableData", "levelData"]),
    ...mapState("levelbonus", ["levelAll", "level"]),
  },
  data() {
    return {
      loading: true,
      tableColumns: [
        {
          prop: "level",
          label: "Level",
          minWidth: 100,
        },
        {
          prop: "type",
          label: "Bonus Type",
          minWidth: 150,
        },
        {
          prop: "amount",
          label: "Value",
          minWidth: 200,
        },
      ],
      types: [
        {
          code: "percentage",
          label: "Percentage",
        },
        {
          code: "fixed_amount",
          label: "Fixed Amount",
        },
        {
          code: "multiplier",
          label: "Multiplier",
        },
      ],
      formData: {
        level: "",
        type: "",
        value: 0,
      },
      dialogFormVisible: false,
    };
  },
  methods: {
    ...mapActions("levelbonus", ["getLevelBonus", "getLevel", "updateLevel"]),
    getLvlBonus() {
      this.getLevelBonus()
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleEdit(index, category) {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading();
          this.getLevel(category.level)
            .then((response) => {
              Swal.hideLoading();
              Swal.close();
              this.formData = {
                level: this.levelData.level,
                type: this.levelData.type,
                value: parseFloat(this.levelData.amount),
              };
              this.dialogFormVisible = true;
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Couldn't fetch data",
              });
            });
        },
      });
    },
    lvlSubmitClick() {
      Swal.showLoading();
      this.updateLevel(this.formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Update level bonus success",
            text: "Update saved",
            onClose: () => {
              this.loading = true;
              this.getLvlBonus();
            },
          });
        })
        .catch((error) => {
          this.$notify({
            message: "Update level bonus failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    }
  }
};
</script>
<style>
</style>>
