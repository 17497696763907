<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header">
          <h4 class="card-title">Basic Point Rule List</h4>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <router-link
              :to="{ name: 'Create Basic Point Settings' }"
              :disabled="!hasRole('create')"
              class="btn pull-right btn-border"
            >ADD BASIC RULE</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table v-loading="loading" :data="basicPointAll">
              <el-table-column
                min-width="300"
                prop="name"
                label="Name"
              ></el-table-column>
              <el-table-column
                min-width="200"
                prop="conditions"
                label="Point By"
              >
                <template scope="scope">
                  <span
                    v-for="(label, index) in scope.row.labels"
                    :key="index"
                    style="color: black"
                  >
                    {{ label }}
                    <br />
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="180"
                prop="conditions"
                align="right"
                label="Basic Point Equals"
              >
                <template scope="scope">
                  <span
                    v-for="(condition, index) in scope.row.conditions"
                    :key="index"
                    style="color: black"
                  >
                    {{
                      condition.field_condition.one_basic_point_equal_to
                        | number
                    }}
                    <br />
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100"
                prop="level"
                header-align="center"
                label="Status"
              >
                <template scope="scope">
                  <span style="color: black">{{ scope.row.level }}</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="150"
                header-align="center"
                label="Actions"
              >
                <template slot-scope="scope">
                  <div class="text-center table-actions">
                    <el-tooltip
                      content="Edit"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="editBasicPointSettings(scope.row)"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="now-ui-icons ui-2_settings-90"></i>
                      </n-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Delete"
                      :open-delay="300"
                      placement="top"
                    >
                      <n-button
                        @click.native="
                          confirmDeleteBasicPointSettings(scope.row)
                        "
                        type="danger"
                        size="sm"
                        icon
                        :disabled="!hasRole('delete')"
                      >
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Table, TableColumn, Loading, MessageBox } from "element-ui";
import {findUserRole} from "@/util/userRoles";
import Helper from "@/util/helper";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    hasRole: Function
  },
  created: function () {
    this.loadData();
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("basicPoint", ["basicPointAll"]),
  },
  methods: {
    ...mapActions("basicPoint", ["getBasicPointAll", "deleteBasicPoint"]),
    loadData: function () {
      this.getBasicPointAll()
        .then((response) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    editBasicPointSettings(data) {
      this.$router.push({
        name: "Update Basic Point Settings",
        params: { id: data.id, slug: Helper.convertToSlug(data.name) }
      });
    },
    confirmDeleteBasicPointSettings(data) {
      MessageBox.confirm(
        `This will permanently delete basic point setting "${data.name}". Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteBasicPoint(data)
            .then((response) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete basic point setting success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete basic point setting failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style>
</style>
