<template>
  <div class="row">
    <div class="col-5">
      <el-select
        class="select-info"
        style="width: 100%"
        size="large"
        placeholder="Select field type"
        v-model="evaluator.code"
        @change="onChangeEvaluatorCode"
      >
        <el-option
          v-for="option in customFieldAll"
          class="select-info"
          :value="option.code"
          :label="option.title"
          :key="option.code"
          :disabled="disableSelectedCriteria(option.code)"
        ></el-option>
      </el-select>
    </div>
    <div class="col-7">
      <span>
        <el-select
          v-if="inputFieldType('boolean')"
          class="select-info"
          style="width: 100%;"
          size="large"
          placeholder="Select here"
          v-model="evaluator.equal_to"
        >
          <el-option
            class="select-info"
            :value="true"
            label="True"
            :key="true"
          ></el-option>
          <el-option
            class="select-info"
            :value="false"
            label="False"
            :key="false"
          ></el-option>
        </el-select>
        <el-date-picker
          v-if="inputFieldType('date')"
          v-model="evaluator.equal_to"
          type="date"
          placeholder="Pick a day"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-date-picker
          v-if="inputFieldType('date_range')"
          v-model="evaluator.value"
          type="daterange"
          style="margin-top: 10px;"
          align="right"
          unlink-panels
          range-separator="-"
          start-placeholder="Start date"
          end-placeholder="End date"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-select
          v-if="inputFieldType('multi_select')"
          class="select-info"
          style="width: 100%;"
          size="large"
          placeholder="Select here"
          multiple
          v-model="evaluator.at_least_one_of"
        >
          <el-option
            v-for="option in selectOptions"
            class="select-info"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
        <div v-if="inputFieldType('multi_text')" style="margin-top: 15px;">
          <el-tag
            :key="tag"
            v-for="tag in evaluator.at_least_one_of"
            closable
            :disable-transitions="false"
            @close="handleTagClose(tag)"
            >{{ tag }}</el-tag
          >
          <el-input
            class="input-new-tag"
            v-if="inputTagVisible"
            v-model="inputTagValue"
            ref="saveTagInput"
            size="mini"
            @keyup.enter.native="handleInputTagConfirm()"
            @blur="handleInputTagConfirm()"
          ></el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="inputTagVisible = true"
            >+ New Tag</el-button
          >
        </div>
        <fg-input
          v-if="inputFieldType('number')"
          v-model="evaluator.equal_to"
          type="number"
        ></fg-input>
        <div
          v-if="inputFieldType('range')"
          class="row"
          style="margin-top: 13px;"
        >
          <div class="col-md-5" style="padding-left: 0px; padding-right: 0px;">
            <fg-input v-model="evaluator.gt" type="number"></fg-input>
          </div>
          <div class="col-md-2 text-center">-</div>
          <div class="col-md-5" style="padding-left: 0px; padding-right: 0px;">
            <fg-input
              v-model="evaluator.lt"
              type="number"
              style="width: 100%"
            ></fg-input>
          </div>
        </div>
        <el-select
          v-if="inputFieldType('select')"
          class="select-info"
          style="width: 100%;"
          size="large"
          placeholder="Select here"
          v-model="evaluator.value"
        >
          <el-option
            v-for="option in selectOptions"
            class="select-info"
            :value="option"
            :label="option"
            :key="option"
          ></el-option>
        </el-select>
        <fg-input
          v-if="inputFieldType('text')"
          v-model="evaluator.equal_to"
          type="text"
          style="margin-top: 13px;"
        ></fg-input>
      </span>
    </div>
  </div>
</template>

<script>
import { Button, DatePicker, Input, Option, Select, Tag } from "element-ui";
import { mapMutations, mapState } from "vuex";

export default {
  name: "RecipientCriteria",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Tag.name]: Tag,
    [Button.name]: Button
  },
  props: {
    index: Number
  },
  data() {
    return {
      evaluator: {
        code: "",
        value: "",
        at_least_one_of: [],
        gt: "",
        lt: "",
        equal_to: "",
        type: ""
      },
      fieldType: {
        options: [],
        value: null,
        min: 1,
        max: 10
      },
      inputTagVisible: false,
      inputTagValue: ""
    };
  },
  computed: {
    ...mapState("customFields", ["customFieldAll"]),
    ...mapState("emailCampaign", ["emailCampaign"]),
    selectOptions() {
      const customFields = this.customFieldAll.find(
        item => item.code === this.evaluator.code
      );
      return customFields.values;
    }
  },
  created() {
    if (this.$route.name === "Campaign Email - Edit Email") {
      if (this.emailCampaign.recipient.type === "by_criteria") {
        this.evaluator = this.emailCampaign.recipient.criteria.evaluators[
          this.index
        ];
        // this.evaluator.code = ev.code;
        // this.evaluator.value = ev.value;
      }
    }
  },
  methods: {
    ...mapMutations("emailCampaign", ["SET_CRITERIA"]),
    inputFieldType(code) {
      let field = this.customFieldAll.find(data => {
        return data.code === this.evaluator.code;
      });
      if (field) {
        code === field.type.code ? console.log(code) : "";
        return code === field.type.code;
      } else {
        return false;
      }
    },
    onChangeEvaluatorCode() {
      this.evaluator.value = null;
      const customFields = this.customFieldAll.find(
        item => item.code === this.evaluator.code
      );
      this.evaluator.type = customFields.type.code;
      this.fieldType.options = customFields.values;
    },
    disableSelectedCriteria(code) {
      const found = this.emailCampaign.recipient.criteria.evaluators.find(
        data => data.code === code
      );
      return found !== undefined;
    },
    copyFormValue(data) {
      this.SET_CRITERIA({ index: this.index, evaluator: data });
    },
    handleTagClose(tag) {
      this.evaluator.at_least_one_of.splice(
        this.evaluator.at_least_one_of.indexOf(tag),
        1
      );
      // this.copyFormValue();
    },
    handleInputTagConfirm() {
      if (
        !this.evaluator.at_least_one_of.find(x => x === this.inputTagValue) &&
        this.inputTagValue
      ) {
        this.evaluator.at_least_one_of.push(this.inputTagValue);
        this.inputTagValue = "";
      }
      // this.copyFormValue();
    }
  },
  watch: {
    evaluator: {
      handler() {
        let newVal = { ...this.evaluator };

        const criteria = this.customFieldAll.find(x => {
          return x.code === newVal.code;
        });

        if (criteria) {
          if (criteria.type.code === "select") {
            newVal.at_least_one_of = [newVal.value];
          } else if (
            criteria.type.code === "date_range" &&
            newVal.value !== null
          ) {
            newVal.gt = `${newVal.value[0]}T00:00:00+07:00`;
            newVal.lt = `${newVal.value[1]}T23:59:59+07:00`;
          } else if (criteria.type.code === "date") {
            newVal.equal_to += "T00:00:00+07:00";
          }

          this.copyFormValue(newVal);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
