<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(btnSubmitClick)">
        <card>
          <div slot="header">
            <h4 class="card-title">Bonus Point Settings</h4>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <label class="col-sm-3 col-form-label">Name of this rules</label>
                <div class="col-sm-5">
                  <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                    <fg-input
                      v-model="bonusPoint.name"
                      type="text"
                      :error="failed ? 'Name is required' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 col-form-label label-padding-top">Bonus type</label>
                <div class="col-sm-5">
                  <div class="form-group">
                    <ValidationProvider name="bonusType" rules="required" v-slot="{ failed }">
                      <el-select
                        v-model="bonusPoint.type"
                        class="select-info"
                        size="large"
                        placeholder="Select bonus type"
                      >
                        <el-option
                          v-for="option in bonusTypes"
                          class="select-info"
                          :value="option.code"
                          :label="option.label"
                          :key="option.code"
                        ></el-option>
                      </el-select>
                      <span class="text-danger invalid-feedback" style="display: block;">
                        {{
                        failed
                        ? `Bonus type is required`
                        : null
                        }}
                      </span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 15px;">
                <label class="col-sm-3 col-form-label">Bonus amount</label>
                <div class="col-sm-5">
                  <ValidationProvider
                    name="oneBonusPointEqual"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="bonusPoint.value"
                      type="number"
                      :error="failed ? '1 point basic equals to is required' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row" style="margin-top: 15px;">
                <label class="col-sm-3 col-form-label">Max point per user</label>
                <div class="col-sm-5">
                  <ValidationProvider
                    name="oneBonusPointEqual"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      v-model="bonusPoint.max_point_per_user"
                      type="number"
                      :error="failed ? '1 point basic equals to is required' : null"
                      :hasSuccess="passed"
                    ></fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h5 class="card-title">Schedule</h5>
              <div class="row">
                <label class="col-sm-2 col-form-label">Start date</label>
                <div class="row">
                  <ValidationProvider name="startDate" rules="required" v-slot="{ failed }">
                    <el-date-picker
                      v-model="bonusPoint.start_date"
                      type="date"
                      placeholder="Pick a day"
                      value-format="yyyy-MM-dd"
                      @change="checkDate"
                    ></el-date-picker>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `Start date is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                  <ValidationProvider name="startTime" rules="required" v-slot="{ failed }">
                    <el-time-picker
                      style="margin-left: 10px;"
                      v-model="time.startTime"
                      placeholder="Pick a time"
                      value-format="HH:mm:ss"
                      @change="checkDate"
                    ></el-time-picker>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `Start time is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="col-sm-2 col-form-label">End date</label>
                <div class="row">
                  <ValidationProvider name="endDate" rules="required" v-slot="{ failed }">
                    <el-date-picker
                      v-model="bonusPoint.end_date"
                      type="date"
                      placeholder="Pick a day"
                      value-format="yyyy-MM-dd"
                      @change="checkDate"
                    ></el-date-picker>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `End date is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                  <ValidationProvider name="endTime" rules="required" v-slot="{ failed }">
                    <el-time-picker
                      style="margin-left: 10px;"
                      v-model="time.endTime"
                      placeholder="Pick a time"
                      value-format="HH:mm:ss"
                      @change="checkDate"
                    ></el-time-picker>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `End time is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </card>
        <condition-list></condition-list>
        <div class="row">
          <div class="col-md-12">
            <n-button native-type="submit" type="info" :disabled="!hasRole()">SUBMIT</n-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
  Select,
  Option,
  Loading,
  MessageBox,
  DatePicker,
  TimePicker,
} from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ConditionList from "./ConditionList";
import { findUserRole } from "@/util/userRoles";
import moment from "moment";

extend("required", required);

export default {
  props: {
    submit: Function,
    role: String
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    "condition-list": ConditionList,
  },
  created: function () {
    this.RESET_BONUS_POINT();
    this.getPointByAll({ onlyBasic: 0 });
    this.getCustomFieldAll({ status: 1 });
    this.getBonusTypes();

    const id = this.$route.params.id;
    if (typeof id !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true
      });

      const module = `bonus point with id: ${id}, name: ${this.$route.params.slug}`;

      this.getBonusPoint({ id, module })
        .then((response) => {
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("references", ["bonusTypes"]),
    ...mapState("bonusPoint", ["bonusPoint", "time"])
  },
  methods: {
    ...mapActions("customFields", ["getCustomFieldAll"]),
    ...mapActions("pointBy", ["getPointByAll"]),
    ...mapActions("references", ["getBonusTypes"]),
    ...mapActions("bonusPoint", ["getBonusPoint"]),
    ...mapMutations("bonusPoint", ["RESET_BONUS_POINT", "RESET_DATE"]),
    hasRole() {
      return findUserRole(this.role, "point_earning_rules");
    },
    btnSubmitClick() {
      if (this.bonusPoint.conditions.length === 0) {
        this.$notify({
          message: "Conditions cannot be empty",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.submit();
      }
    },
    checkDate() {
      const startDate = `${this.bonusPoint.start_date}T${this.time.startTime}+07:00`;
      const endDate = `${this.bonusPoint.end_date}T${this.time.endTime}+07:00`;
      if (this.bonusPoint.start_date && this.bonusPoint.end_date && this.time.startTime && this.time.endTime){
        if (moment(startDate) > moment(endDate)) {
          MessageBox.alert("End date can't be past start date.", "Failed", {
            confirmButtonText: "OK"
          });
          this.RESET_DATE("end");
        }
      }

      if (this.bonusPoint.start_date && this.time.startTime) {
        if (moment(startDate) < moment.now()) {
          MessageBox.alert("Start date can't be past date.", "Failed", {
            confirmButtonText: "OK"
          });
          this.RESET_DATE("start");
        }
      }
    }
  }
};
</script>
<style scoped>
/* .el-select {
  width: 100%;
  height: 50px;
}
.label-padding-top {
  padding-top: 20px;
} */
</style>
