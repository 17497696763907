<template>
  <div>
    <card>
      <h4 slot="header" class="card-title">Users</h4>
      <div class="row">
        <div class="col-sm-12">
          <n-button
            :disabled="!canAddAdmin || !hasRole()"
            class="pull-right btn-border"
            @click.native="toggleModal"
            type="info"
            >ADD ADMIN</n-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="selectedUsers">
            <el-table-column
              min-width="150"
              prop="name"
              label="Name"
            ></el-table-column>
            <el-table-column
              min-width="200"
              prop="email"
              label="Email"
            ></el-table-column>
            <el-table-column
              min-width="150"
              header-align="right"
              label="Actions"
            >
              <template slot-scope="scope">
                <div class="text-right table-actions">
                  <el-tooltip
                    content="Delete"
                    :open-delay="300"
                    placement="top"
                  >
                    <n-button
                      @click.native="deleteUser(scope.$index, scope.row)"
                      :disabled="!hasRole()"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
    <modal :show.sync="showModal" headerClasses="justify-content-center">
      <h3 slot="header"></h3>
      <div class="container pl-5 pr-5">
        <h3>Select admin for this role</h3>
        <fg-input
          style="width: 300px; margin-left: 30%; margin-bottom: 20px;"
          type="text"
          placeholder="Search admin"
          v-model="search"
        ></fg-input>
        <div v-if="users" class="row">
          <div v-for="user in filterUsers" :key="user.id" class="col-md-4">
            <checkbox :value="user.id" v-model="tempSelectedUser" inline>{{
              user.name
            }}</checkbox>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-12 text-center">No users</div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12">
            <n-button
              @click.native="addUser"
              type="info"
              class="pull-right ml-3"
              >ADD</n-button
            >
            <n-button @click.native="toggleModal" class="pull-right"
              >CANCEL</n-button
            >
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Table, TableColumn } from "element-ui";
import { Modal } from "src/components";
import { Checkbox } from "src/components";
import {findUserRole} from "@/util/userRoles";

export default {
  name: "FormUsers",
  props: {
    adminRole: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    Checkbox
  },
  data() {
    return {
      tempSelectedUser: [],
      showModal: false,
      search: ""
    };
  },
  computed: {
    ...mapState("users", ["users"]),
    ...mapState("roles", ["role"]),
    ...mapGetters("roles", ["selectedUsers", "canAddAdmin"]),
    filterUsers: function() {
      const keyword = this.search.trim();
      if (keyword.length) {
        const re = new RegExp(keyword, "i");
        return this.users.filter(item => item.name.search(re) !== -1);
      } else {
        return this.users;
      }
    }
  },
  methods: {
    ...mapActions("roles", ["addUserAccess", "deleteUserAccess"]),
    hasRole() {
      return findUserRole(this.adminRole, "role");
    },
    toggleModal() {
      this.tempSelectedUser = this.role.users;
      this.showModal = !this.showModal;
    },
    addUser() {
      this.addUserAccess(this.tempSelectedUser);
      this.toggleModal();
    },
    deleteUser(index, row) {
      this.deleteUserAccess(row.id);
    }
  }
};
</script>
<style scoped>
.cell {
  color: #000000 !important;
}
</style>
