<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Redeem</h4>
      </div>
      <div class="row">
        <div class="col-sm-2">
          <fg-input
            v-model="filter.search"
            addon-right-icon="fa fa-search"
            placeholder="Search record"
            style="padding-top: 11px; padding-bottom: 11px"
          ></fg-input>
        </div>
        <div class="col-sm-2">
          <el-select
            class="select-info px-1"
            size="large"
            placeholder="Select Voucher"
            v-model="filter.name"
          >
            <el-option
              v-for="option in voucherNames"
              class="select-info"
              :value="option.name"
              :label="option.name"
              :key="option.name"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-2" v-if="false">
          <el-select
            v-model="defaultData"
            class="select-info"
            placeholder="Type"
            size="large"
          >
            <el-option
              v-for="i in 10"
              :key="i"
              :label="i"
              :value="i"
              class="select-info"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-2">
          <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
            <div style="line-height: 2.5"><span>From</span></div>
            <fg-input class="ml-3">
              <el-date-picker
                v-model="filter.period_start"
                placeholder="Start Date"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-3">
          <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
            <div style="line-height: 2.5"><span>To</span></div>
            <fg-input class="ml-3">
              <el-date-picker
                v-model="filter.period_end"
                placeholder="End Date"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-sm">
          <n-button
            class="btn btn-info pull-right"
            @click.native="loadRedeemList()"
          >
            APPLY FILTER
          </n-button>
          <n-button
            class="ml-2 btn-border pull-right"
            @click.native="btnClearFilterClick"
          >
            CLEAR FILTER
          </n-button>
        </div>
      </div>
      <el-table
        v-loading="loading.redeemList"
        :data="redeemList"
        :cell-style="{ verticalAlign: 'top !important' }"
      >
        <el-table-column label="E-Voucher" min-width="150" prop="data">
          <template slot-scope="props">
            <redeem-e-voucher
              :image-url="props.row.voucher_image"
              :name="props.row.voucher_name"
            ></redeem-e-voucher>
          </template>
        </el-table-column>
        <el-table-column label="Transaction" min-width="300" prop="data">
          <template slot-scope="props">
            <redeem-transaction-customer
              :field-data="props.row"
              type="transaction"
            ></redeem-transaction-customer>
          </template>
        </el-table-column>
        <el-table-column label="Customer" min-width="300" prop="id">
          <template slot-scope="props">
            <redeem-transaction-customer
              :field-data="props.row"
              type="customer"
            ></redeem-transaction-customer>
          </template>
        </el-table-column>
        <el-table-column label="Note" prop="id">
          <template slot-scope="props">{{ props.row.order_notes }}</template>
        </el-table-column>
        <el-table-column header-align="right" label="Actions" min-width="150">
          <template slot-scope="scope">
            <div class="text-right table-actions">
              <el-tooltip :open-delay="300" content="Detail" placement="top">
                <n-button
                  type="warning"
                  size="sm"
                  icon
                  class="mr-1"
                  :disabled="!hasRole('read')"
                  @click.native="
                    $router.push({
                      name: 'E-Voucher Management Details',
                      params: {
                        id: scope.row.id,
                        slug: getSlugFromLangs(scope.row.lang),
                      },
                    })
                  "
                >
                  <i class="now-ui-icons tech_tv"></i>
                </n-button>
              </el-tooltip>

              <el-tooltip
                :open-delay="300"
                content="Void Redeem"
                placement="top"
              >
                <n-button
                  type="danger"
                  size="sm"
                  icon
                  class="mr-1"
                  :disabled="scope.row.status === 'void' || !hasRole('delete')"
                  @click.native="voidRedeem(scope.row)"
                >
                  <i class="now-ui-icons media-1_button-power"></i>
                </n-button>
              </el-tooltip>

              <el-tooltip :open-delay="300" content="Print" placement="top">
                <n-button
                  icon
                  size="sm"
                  type="primary"
                  class="mr-1"
                  @click.native="loadTemplate(scope.row)"
                >
                  <i class="now-ui-icons business_briefcase-24"></i>
                </n-button>
              </el-tooltip>
              <el-dialog
                center
                :visible.sync="printDialog"
                :title="printTemplate.userSubject"
                v-show="printDialog"
                v-loading="loading.printTemplate"
                :destroy-on-close="true"
              >
                <div>
                  <div>
                    <iframe
                      id="printArea"
                      :srcdoc="printTemplate.userMessage"
                      frameborder="0"
                      style="width: 100%; height: 50vh"
                    ></iframe>
                  </div>
                  <div class="text-center">
                    <n-button type="primary" @click.native="printNow"
                      >Print
                    </n-button>
                  </div>
                </div>
              </el-dialog>

              <el-tooltip :open-delay="300" content="Send Mail" placement="top">
                <n-button
                  icon
                  size="sm"
                  type="info"
                  @click.native="confirmEmailRedeem(scope.row)"
                  v-loading="loading.sendMail[scope.row.id]"
                >
                  <i class="now-ui-icons ui-1_email-85"></i>
                </n-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pull-right py-3">
        <pagination
          type="info"
          @click.native="loadRedeemList"
          :page-count="pagination.total_pages"
          v-model="pagination.current_page"
        ></pagination>
      </div>
    </card>
  </div>
</template>

<script>
import {
  DatePicker,
  Dialog,
  MessageBox,
  Option,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import RedeemEVoucherVue from "../../components/Vouchers/RedeemEVoucher.vue";
import RedeemTransactionCustomer from "@/components/Vouchers/RedeemTransactionCustomer";
import { mapActions, mapMutations, mapState } from "vuex";
import Pagination from "@/components/Pagination";
import { findUserRole, userRolesCheck } from "@/util/userRoles";
import Helper from "../../util/helper";
import moment from "moment";

export default {
  name: "RedeemList",
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Dialog.name]: Dialog,
    "redeem-e-voucher": RedeemEVoucherVue,
    "redeem-transaction-customer": RedeemTransactionCustomer,
    Pagination,
  },
  data() {
    return {
      defaultData: "",
      loading: {
        redeemList: false,
        sendMail: [],
        printTemplate: false,
      },
      printDialog: false,
      printTemplate: {
        userSubject: "",
        userMessage: "",
      },
    };
  },
  computed: {
    ...mapState("redeem", ["redeemList", "pagination", "filter"]),
    ...mapState("auth", ["roles"]),
    ...mapState("references", ["voucherNames"]),
  },
  methods: {
    ...mapActions("redeem", [
      "getRedeemList",
      "voidVoucher",
      "sendMail",
      "getPrintTemplate",
    ]),
    ...mapActions("references", ["getVoucherNames"]),
    ...mapMutations("redeem", ["CLEAR_FILTER"]),
    hasRole(role) {
      return findUserRole(role, "redeem");
    },
    loadRedeemList() {
      if (this.filter.period_start && this.filter.period_end) {
        if (moment(this.filter.period_start) > moment(this.filter.period_end)) {
          MessageBox.alert("End date can't be past start date.", "Error", {
            confirmButtonText: "OK",
          });
          return;
        }
      }

      this.loading.redeemList = true;
      this.getRedeemList().finally(() => {
        this.loading.redeemList = false;
      });
    },
    voidRedeem(redeem) {
      MessageBox.prompt(
        `This will void redemption. Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          showInput: true,
          inputType: "text",
          inputPlaceholder: "Reason",
          inputPattern: /\S/,
          inputErrorMessage: "Reason is required",
          type: "warning",
        }
      )
        .then(({ value }) => {
          console.log(redeem, value);
          this.loading.redeemList = true;
          this.voidVoucher({
            data: {
              id: redeem.id,
              reason: value,
            },
            redeem: redeem,
          })
            .then(() => {
              this.$notify({
                message: "Void voucher success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
              this.loadRedeemList();
            })
            .catch(() => {
              this.$notify({
                message: "Void voucher failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            })
            .finally(() => {
              this.loading.redeemList = false;
            });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    confirmEmailRedeem(redeem) {
      MessageBox.confirm(
        `This will send redemption email. Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(({ value }) => {
          this.loading.redeemList = true;
          this.sendMail(redeem)
            .then(() => {
              this.$notify({
                message: "Send email success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
              this.loadRedeemList();
            })
            .catch(() => {
              this.$notify({
                message: "Send email failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            })
            .finally(() => {
              this.loading.redeemList = false;
            });
        })
        .catch(() => {
          console.log("cancel");
        });
    },
    loadTemplate(redeem) {
      this.printDialog = true;
      this.loading.printTemplate = true;
      this.getPrintTemplate(redeem).then((res) => {
        this.printTemplate = res.data.data[0];
        this.loading.printTemplate = false;
      });
    },
    printNow() {
      const frm = document.getElementById("printArea").contentWindow;
      frm.focus(); // focus on contentWindow is needed on some ie versions
      frm.print();
    },
    getSlugFromLangs(langs) {
      if (langs.length) {
        const lang = langs[0];
        return Helper.convertToSlug(lang.name);
      }
      return "no-name";
    },
    btnClearFilterClick: function () {
      this.CLEAR_FILTER();
      this.loadRedeemList();
    },
  },
  created() {
    this.loadRedeemList();
    this.getVoucherNames();
    userRolesCheck("read", "redeem");
  },
};
</script>

<style scoped>
@page {
  size: auto; /* auto is the initial value */
  margin: 0; /* this affects the margin in the printer settings */
}
</style>
