<template>
  <div>
    <card v-loading="loading.detail">
      <div slot="header">
        <h4 class="card-title" v-html="redeemName" />
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-2">
          <img :src="redeemDetail.voucher_image" alt="Image" />
        </div>
        <div class="col-sm-12 col-md-3">
          <el-table
            v-loading="false"
            :data="tableData"
            :show-header="false"
            :fit="true"
            :cell-style="{
              paddingTop: 10,
              paddingBottom: 0,
              fontSize: '12px'
            }"
          >
            <el-table-column label="E-Voucher" prop="title">
              <template slot-scope="props">
                <span>{{ props.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label="E-Voucher" prop="value" align="right">
              <template slot-scope="props">
                <span>
                  <b>{{ props.row.value }}</b>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-12 col-md-6">
          <div>
            <h4 class="mt-0">Terms & Condition</h4>
            <div v-html="redeemTNC"></div>
          </div>
        </div>
      </div>
    </card>
    <redeem-detail-list :loading="loading.detail"></redeem-detail-list>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { mapActions, mapState } from "vuex";
import RedeemDetailList from "@/components/Vouchers/RedeemDetailList";
import { userRolesCheck } from "@/util/userRoles";

export default {
  name: "RedeemDetail",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    "redeem-detail-list": RedeemDetailList
  },
  data() {
    return {
      placeholderData: [
        { title: "Point Redeemed", value: "TBD" },
        { title: "Quantity", value: "TBD" },
        { title: "Status", value: "TBD" }
      ],
      loading: {
        detail: false,
      }
    };
  },
  computed: {
    ...mapState("redeem", ["redeemDetail"]),
    ...mapState("auth", ["roles"]),
    tableData() {
      return [
        {
          title: "Point Redeemed",
          value: this.separateNumber(this.redeemDetail.point_redeemed)
        },
        { title: "Quantity", value: this.redeemDetail.quantity },
        { title: "Status", value: this.capitalized(this.redeemDetail.status) }
      ];
    },
    redeemName() {
      if (this.redeemDetail.lang === undefined) return "";

      return this.redeemDetail.lang.find(function(el) {
        return el.lang === "EN";
      })?.name;
    },
    redeemTNC() {
      if (this.redeemDetail.lang === undefined) return "";

      return this.redeemDetail.lang.find(function(el) {
        return el.lang === "EN";
      })?.tnc;
    }
  },
  methods: {
    ...mapActions("redeem", ["getRedeemDetail"]),
    loadRedeemDetail() {
      this.loading.detail = true;
      this.getRedeemDetail({
        id: this.$route.params.id,
        name: this.$route.params.slug
      }).finally(() => {
        this.loading.detail = false;
      });
    },
    separateNumber(x) {
      return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    capitalized(x) {
      if (x == null) return "";

      return x.charAt(0).toUpperCase() + x.slice(1);
    }
  },
  created() {
    this.loadRedeemDetail();
    userRolesCheck("read", "redeem");
  }
};
</script>

<style scoped></style>
