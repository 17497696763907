<template>
  <card>
    <div slot="header">
      <h4 class="card-title">Membership Level History</h4>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="col-md-4">

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <el-table v-loading="loading" :data="membershipLevel.list">
          <el-table-column min-width="100" label="Effective Date">
            <template scope="scope">
              <span style="color: black">{{scope.row.effective_date | formatDate}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Previous Level">
            <template scope="scope">
              <span style="color: black">{{scope.row.current_level | capitalized}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Level">
            <template scope="scope">
              <span style="color: black">{{scope.row.adjust_level | capitalized}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="200" label="Note">
            <template scope="scope">
              <span style="color: black">{{scope.row.note | formatNote }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Approver">
            <template scope="scope">
              <span style="color: black">{{scope.row.approval_name}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Approval Date">
            <template scope="scope">
              <span style="color: black">{{scope.row.approval_date | formatDateTime}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pull-right py-3">
      <n-pagination
        type="info"
        :pageCount="membershipLevel.pagination.totalPages"
        :perPage="membershipLevel.pagination.perPage"
        v-model="currentPage"
        @input="changePage"
      ></n-pagination>
    </div>
  </card>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { Table, TableColumn, MessageBox, Loading } from "element-ui";
import { Pagination } from "src/components";
import moment from "moment";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination
  },
  created: function() {
    this.cardNumber = this.$route.params.cardNumber;
    this.changePage(1);
  },
  computed: {
    ...mapState("members", ["membershipLevel"])
  },
  filters: {
    formatNote(value) {
      if (value === "LevelUpgraded") {
        return "Level Upgraded";
      } else if (value === "LevelDowngraded") {
        return "Level Downgraded";
      } else if (value === "LevelRetained") {
        return "Level Retained";
      }
      
      return value;
    }
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
      cardNumber: ""
    };
  },
  methods: {
    ...mapActions("members", ["getMemberLevelHistories"]),
    parseProperty(property) {
      if (property) {
        const langs = property.lang.filter(item => item.lang === 'EN')
        if (langs.length) {
          return langs[0].name;
        }
      }
      return "-";
    },
    changePage(page) {
      this.currentPage = page;
      this.loading = true;
      this.getMemberLevelHistories({ cardNumber: this.cardNumber, page })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    }
  }
}
</script>
