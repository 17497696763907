var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('campaign-information',{staticStyle:{"height":"700px","overflow":"scroll"},attrs:{"loadingDetail":_vm.loading.getEmailCampaign}})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('campaign-preview',{staticStyle:{"height":"700px","overflow":"scroll"},attrs:{"loadingDetail":_vm.loading.getEmailCampaign}})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('campaign-recipient',{staticStyle:{"height":"300px","overflow":"scroll"},attrs:{"loadingDetail":_vm.loading.getEmailCampaign}})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('campaign-schedule',{staticStyle:{"height":"300px","overflow":"scroll"},attrs:{"loadingDetail":_vm.loading.getEmailCampaign}})],1),_c('div',{staticClass:"mx-3"},[_c('n-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.draft),expression:"loading.draft"}],staticClass:"mr-2 btn btn-outline-info",attrs:{"wide":"","disabled":_vm.loading.draft ||
            _vm.loading.save ||
            !_vm.hasRole('update') ||
            _vm.loading.getEmailCampaign ||
            _vm.emailCampaign.status === 'in_progress' ||
            _vm.emailCampaign.status === 'completed'},nativeOn:{"click":function($event){return _vm.saveCampaign('draft')}}},[_vm._v(" DRAFT ")]),_c('n-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.save),expression:"loading.save"}],staticClass:"btn btn-info",attrs:{"wide":"","disabled":_vm.loading.draft ||
            _vm.loading.save ||
            !_vm.hasRole('update') ||
            _vm.loading.getEmailCampaign ||
            _vm.emailCampaign.status === 'in_progress' ||
            _vm.emailCampaign.status === 'completed'},nativeOn:{"click":function($event){return _vm.saveCampaign('active')}}},[_vm._v(" SAVE ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }