<template>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <el-select
          class="select-info"
          style="width: 100%"
          size="large"
          placeholder="Select field type"
          v-model="evaluator.code"
          @change="onChangeEvaluatorCode"
        >
          <el-option
            v-for="option in customFieldAll"
            class="select-info"
            :value="option.code"
            :label="option.title"
            :key="option.code"
            :disabled="disableOptionFieldType(indexRow, option.code)"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="col-md-5">
      <span>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'boolean'">
          <el-select
            class="select-info"
            style="width: 100%;"
            size="large"
            placeholder="Select here"
            v-model="evaluator.equal_to"
          >
            <el-option class="select-info" :value="true" label="True" :key="true"></el-option>
            <el-option class="select-info" :value="false" label="False" :key="false"></el-option>
          </el-select>
        </div>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'date'">
          <el-date-picker
            v-model="evaluator.equal_to"
            type="date"
            placeholder="Pick a day"
          ></el-date-picker>
        </div>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'date_range'">
          <el-date-picker
            v-model="fieldType.value"
            type="daterange"
            style="margin-top: 10px;"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'multi_select'">
          <el-select
            class="select-info"
            style="width: 100%;"
            size="large"
            placeholder="Select here"
            multiple
            v-model="evaluator.at_least_one_of"
          >
            <el-option
              v-for="option in fieldType.options"
              class="select-info"
              :value="option"
              :label="option"
              :key="option"
            ></el-option>
          </el-select>
        </div>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'multi_text'">
          <el-tag
            :key="tag"
            v-for="tag in evaluator.at_least_one_of"
            closable
            :disable-transitions="false"
            @close="handleTagClose(tag)"
          >{{tag}}</el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputTagVisible"
            v-model="inputTagValue"
            ref="saveTagInput"
            size="mini"
            @keyup.enter.native="handleInputTagConfirm()"
            @blur="handleInputTagConfirm()"
          ></el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInputTag">+ New Tag</el-button>
        </div>
        <fg-input
          v-if="inputFieldType(indexRow, $vnode.key) == 'number'"
          v-model="evaluator.equal_to"
          type="number"
        ></fg-input>
        <div
          v-if="inputFieldType(indexRow, $vnode.key) == 'range'"
          class="row"
        >
          <div class="col-md-5" style="padding-left: 0px; padding-right: 0px;">
            <fg-input v-model="evaluator.gt" type="number"></fg-input>
          </div>
          <div class="col-md-2 text-center">-</div>
          <div class="col-md-5" style="padding-left: 0px; padding-right: 0px;">
            <fg-input v-model="evaluator.lt" type="number" style="width: 100%"></fg-input>
          </div>
        </div>
        <div class="form-group" v-if="inputFieldType(indexRow, $vnode.key) == 'select'">
          <el-select
            class="select-info"
            style="width: 100%;"
            size="large"
            placeholder="Select here"
            v-model="fieldType.value"
          >
            <el-option
              v-for="option in fieldType.options"
              class="select-info"
              :value="option"
              :label="option"
              :key="option"
            ></el-option>
          </el-select>
        </div>
        <fg-input
          v-if="inputFieldType(indexRow, $vnode.key) == 'text'"
          v-model="evaluator.equal_to"
          type="text"
          style="margin-top: 13px;"
        ></fg-input>
      </span>
    </div>
    <div class="col-md-1">
      <a v-if="$vnode.key > 0" href="#" @click="btnDeleteAnd()" class="text-danger">X</a>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { Select, Option, DatePicker, Input, Tag, Button } from "element-ui";
export default {
  props: {
    evaluator: Object,
    indexRow: Number,
    disableOptionFieldType: Function,
    inputFieldType: Function,
    deleteAnd: Function,
    updateEvaluatorValue: Function,
    deleteEvaluatorValue: Function,
    changeEvaluator: Function,
    addEvaluatorTagValue: Function,
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  created: function () {
    this.parseFieldValue();
  },
  data() {
    return {
      fieldType: {
        options: [],
        value: null,
        min: 1,
        max: 10,
      },
      inputTagVisible: false,
      inputTagValue: "",
    };
  },
  computed: {
    ...mapState("customFields", ["customFieldAll"]),
    onChangeFieldTypeValue: function () {
      return this.fieldType.value;
    },
  },
  watch: {
    onChangeFieldTypeValue: function (newVal, oldVal) {
      if (newVal !== null && newVal.length) {
        this.updateEvaluatorValue({
          indexRow: this.indexRow,
          indexItem: this.$vnode.key,
          values: newVal,
        });
      }
    },
  },
  methods: {
    parseFieldValue: function () {
      if (this.evaluator.type === "date_range") {
        if (this.evaluator.gt !== "" && this.evaluator.lt !== "") {
          this.fieldType.value = [
            this.evaluator.gt.split("T")[0],
            this.evaluator.lt.split("T")[0],
          ];
        }
      } else if (this.evaluator.type === "select") {
        this.fieldType.value = this.evaluator.at_least_one_of[0];
      }
    },
    btnDeleteAnd: function () {
      this.deleteAnd({ indexRow: this.indexRow, indexItem: this.$vnode.key });
    },
    showInputTag() {
      this.inputTagVisible = true;
    },
    handleInputTagConfirm() {
      let inputTagValue = this.inputTagValue;
      if (inputTagValue) {
        this.addEvaluatorTagValue({
          indexRow: this.indexRow,
          indexItem: this.$vnode.key,
          tag: inputTagValue,
        });
      }
      this.inputTagVisible = false;
      this.inputTagValue = "";
    },
    handleTagClose(tag) {
      this.deleteEvaluatorValue({
        indexRow: this.indexRow,
        indexItem: this.$vnode.key,
        tag: tag,
      });
    },
    onChangeEvaluatorCode: function () {
      this.fieldType.value = null;
      const customFields = this.customFieldAll.filter(
        (item) => item.code == this.evaluator.code
      );
      if (customFields.length) {
        const customField = customFields[0];
        this.fieldType.options = customField.values;
        this.changeEvaluator({
          indexRow: this.indexRow,
          indexItem: this.$vnode.key,
          code: this.evaluator.code,
          type: customField.type.code,
        });
      }
    },
  },
};
</script>
<style scoped>
.el-select {
  width: 100%;
}
.el-tag {
  margin: 0px 5px 5px 0px !important;
}
.el-tag--info {
  border-color: transparent !important;
}
.el-tag__close {
  color: black !important;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-top: 5px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: middle;
}
</style>