<template>
  <div>
    <card v-loading="loading || loadingList">
      <div slot="header">
        <h4 class="card-title">REDEEM</h4>
      </div>
      <div class="row">
        <div class="col-sm-2">
          <el-select
            v-model="redeemDetailFilter.property_code"
            class="select-info"
            placeholder="Property"
            size="large"
          >
            <el-option
              v-for="(p, i) in propertyList"
              :key="i"
              :label="p.langs[0].name"
              :value="p.code"
              class="select-info"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-2">
          <fg-input
            v-model="redeemDetailFilter.e_gift_search"
            addon-right-icon="fa fa-search"
            placeholder="Search"
            style="padding-top: 11px; padding-bottom: 11px"
          ></fg-input>
        </div>
        <div class="col-sm-2">
          <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
            <div style="line-height: 2.5"><span>From&nbsp;</span></div>
            <fg-input class="ml-3">
              <el-date-picker
                v-model="redeemDetailFilter.expired_start_date"
                placeholder="Expired Start Date"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2">
          <div style="padding-top: 11px; padding-bottom: 11px; display: flex">
            <div style="line-height: 2.5"><span>To&nbsp;</span></div>
            <fg-input class="ml-3">
              <el-date-picker
                v-model="redeemDetailFilter.expired_end_date"
                placeholder="Expired End Date"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2">
          <el-select
            v-model="redeemDetailFilter.e_gift_status"
            class="select-info"
            placeholder="Status"
            size="large"
          >
            <el-option
              v-for="s in status"
              :key="s.value"
              :label="s.name"
              :value="s.value"
              class="select-info"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm">
          <n-button
            class="pull-right mr-2 btn btn-info"
            @click.native="loadRedeemDetailList"
          >
            APPLY FILTER
          </n-button>
        </div>
      </div>
      <hr />
      <div class="pull-right mr-2">
        <n-button
          class="btn btn-info"
          @click.native="confirmRedeem()"
          :disabled="
            redeemDetail.status === 'void' || selectedData.length === 0 || !hasRole('update')
          "
        >
          REDEEM SELECTED
        </n-button>
      </div>

      <div>
        <el-table
          :data="redeemDetail.eGifts"
          class="mb-5"
          ref="redeemDetailListTable"
          @selection-change="changeSelection"
          :header-row-style="{
            fontSize: '10px'
          }"
        >
          <el-table-column
            type="selection"
            width="55"
            :selectable="canSelectRow"
          ></el-table-column>
          <el-table-column
            prop="item_id"
            align="right"
            header-align="center"
            label="Value"
          >
            <template slot-scope="props">
              {{ separateNumber(props.row.value) }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" align="center" min-width="130" label="Redemption Code">
            <template slot-scope="props">
              {{ props.row.e_gift_code }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" label="Expired">
            <template slot-scope="props">
              {{ formatDate(props.row.expired) }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" label="Redemption">
            <template>
              {{ formatDate(redeemDetail.transaction_date) }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" label="Utilized Date">
            <template slot-scope="props">
              {{ formatDate(props.row.used_date) }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" label="Utilized By">
            <template slot-scope="props">
              {{ props.row.admin_email }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" align="center" label="Status">
            <template slot-scope="props">
              {{ props.row.status | capitalized }}
            </template>
          </el-table-column>
          <el-table-column prop="item_id" label="Actions">
            <template slot-scope="props">
              <el-tooltip :open-delay="300" content="Redeem" placement="top">
                <n-button
                  icon
                  size="sm"
                  type="success"
                  @click.native="confirmRedeem(props.row)"
                  :disabled="
                    props.row.status !== 'activated' ||
                    redeemDetail.status === 'void' ||
                    !hasRole('update') ||
                    isExpired(props.row)
                  "
                >
                  <i class="now-ui-icons shopping_bag-16"></i>
                </n-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
  </div>
</template>

<script>
import {
  DatePicker,
  MessageBox,
  Option,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import {findUserRole} from "@/util/userRoles";

export default {
  name: "RedeemDetailList",
  props: {
    loading: Boolean
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      filter: {
        property: "",
        search: "",
        period_start: "",
        period_end: "",
        status: ""
      },
      selectedData: [],
      status: [
        { name: "Activated", value: "activated" },
        { name: "Used", value: "used" },
        { name: "Suspended", value: "suspended" },
        { name: "Expired", value: "expired" },
      ],
      loadingList: false
    };
  },
  computed: {
    ...mapState("redeem", [
      "redeemDetail",
      "propertyList",
      "redeemDetailFilter",
    ]),
  },
  methods: {
    ...mapActions("redeem", [
      "redeemVoucher",
      "getPropertyList",
      "getRedeemDetail",
    ]),
    isExpired(row) {
      const expired = Date.parse(row.expired);
      const current = new Date().getTime();

      return current > expired;
    },
    hasRole(role) {
      return findUserRole(role, "redeem");
    },
    separateNumber(x) {
      return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(d) {
      return d && moment(d).format("D MMM YYYY");
    },
    changeSelection(x) {
      this.selectedData = x;
    },
    confirmRedeem(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        ids = this.selectedData;
      }
      ids = ids.map((data) => data.code);
      MessageBox.confirm(
        `This will redeem selected voucher. Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.redeemVoucher(ids)
          .then((response) => {
            if (response.data.data.length > 0 && response.data.data[0].status === 'used_or_expired') {
              this.$notify({
                message: "Redeem failed, voucher already used or expired",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            } else {
              this.$notify({
                message: "Redeem success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$notify({
              message: "Redeem failed",
              timeout: 5000,
              icon: "now-ui-icons ui-1_bell-53",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "danger",
            });
          })
          .finally(() => {
            this.loading = false;
            this.loadRedeemDetailList();
          });
      });
    },
    canSelectRow(row, i) {

      return row.status === "activated" && !this.isExpired(row);
    },
    loadRedeemDetailList() {
      this.loadingList = true;
      this.getRedeemDetail({
        id: this.$route.params.id,
        name: this.$route.params.slug
      }).finally(() => {
        this.loadingList = false;
      });
    }
  },
  created() {
    this.getPropertyList();
  },
};
</script>

<style scoped></style>
