<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(btnSubmitClick)">
        <card>
          <div slot="header">
            <h4 class="card-title">Upload your html email template</h4>
            <div class="row justify-content-center">
              <div class="col-md-10">
                <label>Language</label>
                <hr />
                <span v-if="template.langs.length > 0">
                  <div v-for="(item, index) in template.langs" :key="index">
                    <div class="row">
                      <div class="col-md-3">
                        <el-select
                          class="select-info"
                          size="large"
                          placeholder="Select language"
                          v-model="item.lang"
                        >
                          <el-option
                            v-for="option in languages"
                            class="select-info"
                            :value="option.code"
                            :label="option.country"
                            :key="option.code"
                            :disabled="isSelectedLanguage(option.code)"
                          ></el-option>
                        </el-select>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group row" style="margin-top: 13px">
                          <label
                            for="staticEmail"
                            class="col-sm-3 col-form-label"
                            >Subject</label
                          >
                          <div class="col-sm-9">
                            <ValidationProvider
                              name="subject"
                              rules="required"
                              v-slot="{ passed, failed }"
                            >
                              <fg-input
                                type="text"
                                :error="failed ? 'Subject is required' : null"
                                :hasSuccess="passed"
                                v-model="item.subject"
                              ></fg-input>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-sm-4">
                            <label
                              for="staticEmail"
                              style="margin-top: 14px"
                              class="col-form-label"
                              >Upload file</label
                            >
                          </div>
                          <div class="col-sm-8">
                            <input
                              @change="onChangeFileUpload($event, index)"
                              type="file"
                              style="padding-top: 18px; width: 100%;"
                              accept="text/html"
                            />
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 20px; margin-left: 50px">
                        <a
                          v-if="index > 0"
                          href="javascript:void(0);"
                          @click="deleteTemplate(index)"
                          style="color: red"
                          >X</a
                        >
                      </div>
                    </div>
                    <hr />
                  </div>
                </span>
                <div v-else class="row" style="text-center">
                  <div class="col-md-12">
                    <div class="text-center">Loading...</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <n-button
                      @click.native="addTemplate"
                      class="pull-right btn-border"
                      type="info"
                      :disabled="disableButton"
                      >ADD LANGUAGE</n-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
        <div class="row">
          <div class="col-md-12">
            <n-button
              :disabled="disableButton || !hasRole('update')"
              native-type="submit"
              class="btn"
              type="info"
              >SAVE TEMPLATE</n-button
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { Select, Option, Loading, MessageBox } from "element-ui";
import {findUserRole, userRolesCheck} from "@/util/userRoles";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

extend("required", required);

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },

  created: function () {
    userRolesCheck("read", "system_configuration");
    this.RESET_TEMPLATE();
    this.getLanguages();
    const code = this.$route.params.code;
    if (typeof code !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.getTemplate(code)
        .then((response) => {
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("references", ["languages"]),
    ...mapState("notification", ["template"]),
    ...mapGetters("notification", ["isSelectedLanguage", "disableButton"]),
  },
  methods: {
    ...mapActions("references", ["getLanguages"]),
    ...mapActions("notification", ["getTemplate", "saveTemplate"]),
    ...mapMutations("notification", [
      "ADD_TEMPLATE",
      "DELETE_TEMPLATE",
      "UPDATE_TEMPLATE_CONTENT",
      "RESET_TEMPLATE",
    ]),
    hasRole(role){
      return findUserRole(role, "system_configuration");
    },
    async onChangeFileUpload($event, index) {
      console.log($event.target.files);
      const file = $event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () =>
        this.UPDATE_TEMPLATE_CONTENT({ index: index, template: reader.result });
    },
    addTemplate: function () {
      this.ADD_TEMPLATE();
    },
    deleteTemplate: function (index) {
      this.DELETE_TEMPLATE(index);
    },
    btnSubmitClick: function () {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.saveTemplate()
        .then((response) => {
          loadingInstance.close();

          Swal.fire({
            icon: "success",
            title: "Update template success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "Settings" });
            }
          });
        })
        .catch((error) => {
          console.log(error);
          loadingInstance.close();
          this.$notify({
            message: "Update template failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        });
    }
  }
};
</script>
<style scoped>
.el-select {
  width: 80%;
}
</style>
