<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(btnSubmitClick)">
        <card class="py-5">
          <div slot="header">
            <h4 class="card-title">Revenue Point By</h4>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">Name</label>
            <div class="col-sm-9 col-lg-8">
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  v-model="pointBy.label"
                  type="text"
                  :error="failed ? 'Name is required' : null"
                  :hasSuccess="passed"
                ></fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label label-padding-top"
              >Revenue</label
            >
            <div class="col-md-9 col-lg-8">
              <div class="form-group">
                <el-select
                  class="select-info"
                  size="large"
                  multiple
                  placeholder="Select Revenue"
                  v-model="pointBy.childs"
                >
                  <el-option
                    v-for="option in pointByAll"
                    class="select-info"
                    :value="option.code"
                    :label="option.label"
                    :key="option.code"
                  ></el-option>
                </el-select>
              <!-- <el-tag
                :key="tag"
                v-for="(tag, i) in settings.selectRevenue"
                closable
                :disable-transitions="false"
                @close="handleTagClose(i)"
                >{{ tag }}</el-tag
              > -->
              </div>
            </div>
          </div>
        </card>
        <div class="row">
          <div class="col-md-12">
            <n-button native-type="submit" type="info" :disabled="!hasRole()">Save</n-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Select, Option, Loading, Tag } from "element-ui";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Switch } from "src/components/index";
import {findUserRole} from "@/util/userRoles";
extend("required", required);

export default {
  props: {
    submit: Function,
    role: String
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
    [Tag.name]: Tag,
  },
  created: function () {
    this.RESET_FORM();
    this.getPointByAll({ onlyBasic: 1 });
    const code = this.$route.params.code;
    if (typeof code !== "undefined") {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });

      const module = `point by with id: ${code}, name: ${this.$route.params.slug}`;

      this.getPointBy({ code, module })
        .then((response) => {
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    }
  },
  computed: {
    ...mapState("pointBy", ["pointByAll", "pointBy"]),
  },
  methods: {
    ...mapActions("pointBy", ["getPointByAll", "getPointBy"]),
    ...mapMutations("pointBy", ["RESET_FORM"]),
    hasRole() {
      return findUserRole(this.role, "point_earning_rules");
    },
    btnSubmitClick() {
      if (this.pointBy.childs.length === 0) {
        this.$notify({
          message: "Revenue cannot be empty",
          timeout: 5000,
          icon: "now-ui-icons ui-1_bell-53",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        this.submit();
      }
    },
  },
};
</script>
<style scoped>
/* .el-select {
  width: 100%;
  height: 50px;
}
.label-padding-top {
  padding-top: 20px;
} */
</style>
