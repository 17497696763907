<template>
  <card no-footer-line>
    <div slot="header">
      <h4 class="card-title">Level Adjustment</h4>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <n-button
          @click.native="$router.push({ name: 'Level Adjustment Create' })"
          :disabled="!hasRole('create')"
          class="btn pull-right btn-border"
        >
          LEVEL ADJUSTMENT FORM
        </n-button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <el-table v-loading="loading" cell-class-name="align-top" :data="tableData">
          <el-table-column label="Name" prop="member" min-width="200">
            <div class="td-name" slot-scope="props">
              <span>{{ showFullName(props.row.member) }}</span>
              <br />
              <span style="font-size: 12px; font-weight: lighter">{{
                props.row.member.card_number
              }}</span>
            </div>
          </el-table-column>
          <el-table-column
            label="Current"
            prop="current_level"
            min-width="120"
          ></el-table-column>
          <el-table-column
            label="Adjustment"
            prop="adjust_level"
            min-width="160"
          ></el-table-column>
          <el-table-column
            label="Effective date"
            prop="effective_date"
            min-width="170"
          ></el-table-column>
          <el-table-column
            label="Requestor"
            prop="requestor_name"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="Approver"
            prop="approval_name"
            min-width="130"
          ></el-table-column>
          <el-table-column min-width="100" label="Status" prop="status"></el-table-column>
          <el-table-column label="Actions" min-width="150">
            <div slot-scope="props" class="table-actions">
              <el-tooltip content="Approve" :open-delay="300" placement="top">
                <n-button
                  @click.native="
                    handleResponse('approve', props.$index, props.row)
                  "
                  type="success"
                  size="sm"
                  icon
                  :disabled="isDisabled('approval', props.row)"
                >
                  <i class="now-ui-icons ui-1_check"></i>
                </n-button>
              </el-tooltip>
              <el-tooltip content="Reject" :open-delay="300" placement="top">
                <n-button
                  @click.native="
                    handleResponse('reject', props.$index, props.row)
                  "
                  type="primary"
                  size="sm"
                  icon
                  :disabled="isDisabled('approval', props.row)"
                >
                  <i class="now-ui-icons ui-1_simple-delete"></i>
                </n-button>
              </el-tooltip>
              <el-tooltip content="Edit" :open-delay="300" placement="top">
                <n-button
                  @click.native="handleEdit(props.$index, props.row)"
                  type="info"
                  size="sm"
                  icon
                >
                  <i class="now-ui-icons ui-2_settings-90"></i>
                </n-button>
              </el-tooltip>
              <el-tooltip content="Delete" :open-delay="300" placement="top">
                <n-button
                  @click.native="handleDelete(props.$index, props.row)"
                  type="danger"
                  size="sm"
                  icon
                  :disabled="isDisabled('delete', props.row)"
                >
                  <i class="now-ui-icons ui-1_simple-remove"></i>
                </n-button>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div>
        <p class="card-category">
          Showing {{ adjustmentList.meta.pagination.current_page }} to
          {{ adjustmentList.meta.pagination.total_pages }} of
          {{ adjustmentList.meta.pagination.total }} entries
        </p>
      </div>
      <n-pagination
        type="info"
        v-model="adjustmentList.meta.pagination.current_page"
        :per-page="adjustmentList.meta.pagination.per_page"
        :total="adjustmentList.meta.pagination.total"
        @input="updatePage"
      >
      </n-pagination>
    </div>
  </card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Loading, MessageBox } from "element-ui";
import { Pagination as NPagination } from "src/components";
import {findUserRole, userRolesCheck} from "@/util/userRoles";

export default {
  components: {
    NPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      loading: true,
      page: 1,
      limit: 30,
      module_name: "membership_level_adjustment"
    };
  },
  computed: {
    ...mapGetters("levelAdjustment", ["tableData"]),
    ...mapState("levelAdjustment", ["adjustmentList"]),
    ...mapState("auth", ["roles"])
  },
  methods: {
    ...mapActions("levelAdjustment", [
      "getAdjustmentList",
      "responseLevelAdjustment",
      "deleteLevelAdjustment"
    ]),
    hasRole(role) {
      return findUserRole(role, "manual_level_adjustment");
    },
    showFullName(member) {
      let full_name = "";

      if (member.first_name) {
        full_name = full_name + member.first_name;
      }
      if (member.last_name) {
        full_name = full_name + " " + member.last_name;
      }
      return full_name.trim();
    },
    isDisabled(action, row) {
      return !(row.status === "Pending" && this.hasRole(action));
    },
    updatePage(page) {
      if (page !== this.page) {
        this.page = page;

        this.$router.replace({
          name: "Level Adjustment List",
          query: { page: this.page }
        });

        this.loading = true;
        this.getAdjustmentList({ page: this.page, limit: this.limit })
          .then(response => {
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
          });
      }
    },
    handleEdit(index, data) {
      this.$router.push({
        name: "Level Adjustment Edit",
        params: { id: data.id }
      });
    },
    handleResponse(action, index, data) {
      MessageBox.confirm(
        `Are you sure want to ${action} this request?`,
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.responseLevelAdjustment({
            data: data,
            action: action,
            index: index
          })
            .then(response => {
              loadingInstance.close();

              let text = "";

              if (action === "approve") {
                text = "approved";
              } else if (action === "reject") {
                text = "rejected";
              }

              this.$notify({
                message: `You have successfully ${text} level adjustment request`,
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
              });
            })
            .catch(error => {
              loadingInstance.close();
              this.$notify({
                message: "Failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleDelete(index, data) {
      MessageBox.confirm(
        `This will permanently delete level adjustment for ${data.member.first_name}. Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true
          });
          this.deleteLevelAdjustment(data)
            .then(response => {
              loadingInstance.close();
              this.$notify({
                message: "Delete level adjustment success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success"
              });
            })
            .catch(error => {
              loadingInstance.close();
              console.log(error);
              this.$notify({
                message: "Delete level adjustment failed.",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  created: function() {
    userRolesCheck("read", "manual_level_adjustment");
    this.page =
      typeof this.$route.query.page === "undefined"
        ? this.page
        : this.$route.query.page;
    this.getAdjustmentList({ page: this.page, limit: this.limit })
      .then(response => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
      });
  }
};
</script>

<style></style>
