<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Point Adjustment</h4>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-3">
              <fg-input
                v-model="filter.search"
                addon-right-icon="fa fa-search"
                placeholder="Search record"
                style="padding-top: 11px; padding-bottom: 11px"
              ></fg-input>
            </div>
            <div class="col-sm-4">
              <el-select
                v-model="filter.type"
                class="select-info"
                placeholder="Type"
                size="large"
              >
                <el-option
                  v-for="(option, i) in adjustmentType"
                  :key="i"
                  :label="option.type"
                  :value="option.value"
                  class="select-info"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <n-button
            :disabled="!hasRole('create')"
            @click.native="
              $router.push({
                name: 'Manual Adjustment - New Point Adjustment Form',
              })
            "
            class="btn pull-right btn-border"
          >
            ADJUSTMENT FORM
          </n-button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table
            v-loading="loading.pointAdjustmentList"
            :data="pointAdjustmentList"
          >
            <el-table-column label="Conf No" min-width="150" prop="member">
              <template slot-scope="props">
                {{ props.row.check_no }}
              </template>
            </el-table-column>
            <el-table-column label="Name" min-width="200" prop="member">
              <template slot-scope="props">
                {{
                  `${props.row.member.first_name} ${props.row.member.last_name}`
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="Type"
              min-width="100"
              prop="point_value_type"
            ></el-table-column>
            <el-table-column label="Night" align="right" min-width="150">
              <template slot-scope="props">
                {{ props.row.night_value }}
              </template>
            </el-table-column>
            <el-table-column label="Point" align="right" min-width="150">
              <template slot-scope="props">
                {{ props.row.point_value }}
              </template>
            </el-table-column>
            <el-table-column label="Bonus" align="right" min-width="150">
              <template slot-scope="props">
                {{ props.row.bonus_point_value }}
              </template>
            </el-table-column>
            <el-table-column
              label="Visit"
              align="center"
              min-width="150"
              prop="place"
            ></el-table-column>
            <el-table-column
              label="Date"
              min-width="150"
              prop="transaction_date"
            >
              <template slot-scope="props">
                {{ `${formatDate(props.row.transaction_date)}` }}
              </template>
            </el-table-column>
            <el-table-column
              label="Requested By"
              min-width="200"
              prop="requestor_name"
            ></el-table-column>
            <el-table-column label="Status" min-width="150">
              <template slot-scope="props">
                {{ props.row.status | capitalized }}
              </template>
            </el-table-column>
            <el-table-column
              header-align="right"
              label="Actions"
              min-width="150"
            >
              <template slot-scope="scope">
                <div class="text-right table-actions">
                  <el-tooltip
                    :open-delay="300"
                    content="Approve"
                    placement="top"
                  >
                    <n-button
                      type="success"
                      size="sm"
                      icon
                      class="mr-1"
                      :disabled="!hasRole('approval')"
                      @click.native="
                        $router.push({
                          name: 'Manual Adjustment - Approve Point Adjustment Form',
                          params: {
                            id: scope.row.id,
                          },
                        })
                      "
                    >
                      <i class="now-ui-icons ui-1_check"></i>
                    </n-button>
                  </el-tooltip>

                  <el-tooltip :open-delay="300" content="Edit" placement="top">
                    <n-button
                      icon
                      size="sm"
                      type="info"
                      class="mr-1"
                      @click.native="
                        $router.push({
                          name: 'Manual Adjustment - Point Adjustment Form',
                          params: {
                            id: scope.row.id,
                          },
                        })
                      "
                    >
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>

                  <el-tooltip
                    :open-delay="300"
                    content="Delete"
                    placement="top"
                  >
                    <n-button
                      icon
                      size="sm"
                      type="danger"
                      :disabled="
                        !hasRole('delete') || scope.row.status === 'approved'
                      "
                      @click.native="confirmDeletePointAdjustment(scope.row)"
                    >
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pull-right py-3">
        <n-pagination
          type="info"
          :pageCount="pagination.total_pages"
          :perPage="pagination.per_page"
          v-model="pagination.current_page"
          @input="changePage"
        />
      </div>
    </card>
  </div>
</template>

<script>
import {
  Loading,
  MessageBox,
  Option,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { Pagination } from "src/components";
import { findUserRole, userRolesCheck } from "@/util/userRoles";

const cancelToken = axios.CancelToken;
let cancelSource = null;

export default {
  name: "PointAdjustmentList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    [Pagination.name]: Pagination,
  },
  created: function () {
    this.loadPointAdjustment();
    userRolesCheck("read", "manual_point_adjustment");
  },
  data() {
    return {
      loading: {
        pointAdjustmentList: false,
      },
      filterType: "",
      adjustmentType: [
        { value: "earn", type: "Earn" },
        { value: "deduct", type: "Deduction" },
      ],
      defaultPagination: 1,
    };
  },
  computed: {
    ...mapState("pointAdjustment", [
      "filter",
      "pointAdjustmentList",
      "pagination",
    ]),
    ...mapState("auth", ["roles", "userId"]),
  },
  methods: {
    ...mapActions("pointAdjustment", [
      "getPointAdjustmentList",
      "deletePointAdjustment",
    ]),
    hasRole(role) {
      return findUserRole(role, "manual_point_adjustment");
    },
    addPoint(point, bonusPoint) {
      return point + bonusPoint;
    },
    formatDate(d) {
      if (d === "0000-00-00") return "-";

      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(d);
      return `${date.getDate()} ${
        month[date.getMonth()]
      } ${date.getFullYear()}`;
    },
    loadPointAdjustment() {
      // Cancel sent request
      cancelSource && cancelSource.cancel("Cancel request");

      // Create new source
      cancelSource = cancelToken.source();

      this.loading.pointAdjustmentList = true;
      this.getPointAdjustmentList(cancelSource).then(() => {
        this.loading.pointAdjustmentList = false;
      });
    },
    changePage() {
      this.loadPointAdjustment();
    },
    confirmDeletePointAdjustment(data) {
      MessageBox.confirm(
        `This will permanently delete point adjustment for ${data.member.first_name} with confirmation no: ${data.check_no}. Continue?`,
        "Confirmation",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          let loadingInstance = Loading.service({
            fullscreen: true,
          });
          this.deletePointAdjustment(data)
            .then((response) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete point adjustment success",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch((error) => {
              loadingInstance.close();
              this.$notify({
                message: "Delete point adjustment failed",
                timeout: 5000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
              });
            })
            .finally(() => {
              this.loadPointAdjustment();
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    filter: {
      handler() {
        this.loadPointAdjustment();
      },
      deep: true,
    },
  },
};
</script>
