<template>
  <card v-loading="loadingDetail">
    <h3 class="card-title">Preview</h3>
    <h4>{{ emailCampaign.langs[currentLangIndex].subject }}</h4>
    <p>{{ emailCampaign.langs[currentLangIndex].preheader }}</p>
    <hr />
    <div class="quillWrapper">
      <div id="quill-container" class="ql-container ql-snow" style="border: none">
        <div class="ql-editor" v-html="emailCampaign.langs[currentLangIndex].template"></div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CampaignPreview",
  props: {
    loadingDetail: Boolean
  },
  computed: {
    ...mapState("emailCampaign", ["emailCampaign", "currentLangIndex"])
  }
};
</script>

<style scoped>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>
