<template>
  <form-bonus-point-settings :submit="submit" role="update"></form-bonus-point-settings>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Loading, MessageBox } from "element-ui";

import FormBonusPointSettings from "./FormBonusPointSettings";
import { userRolesCheck } from "@/util/userRoles";
import Swal from "sweetalert2";
export default {
  components: {
    "form-bonus-point-settings": FormBonusPointSettings,
  },
  methods: {
    ...mapActions("references", ["getBonusTypes"]),
    ...mapActions("bonusPoint", ["saveBonusPoint"]),
    submit() {
      let loadingInstance = Loading.service({
        fullscreen: true,
      });
      this.saveBonusPoint()
        .then((response) => {
          loadingInstance.close();
          Swal.fire({
            icon: "success",
            title: "Update bonus point success",
            text: "Update saved",
            onClose: () => {
              this.$router.push({ name: "PointEarningRules" });
            }
          });
        })
        .catch((error) => {
          loadingInstance.close();
          this.$notify({
            message: "Update bonus point failed",
            timeout: 5000,
            icon: "now-ui-icons ui-1_bell-53",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        });
    }
  }
};
</script>
<style scoped>
</style>
