<template>
  <div>
    <card>
      <div slot="header">
        <h4 class="card-title">Conditions</h4>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table v-loading="loading" cell-class-name="align-top" :data="bonusPoint.conditions">
            <el-table-column min-width="300" prop="evaluators" header-align="center" label="Criteria">
              <template scope="scope">
                <criteria-list
                  :evaluators="scope.row.evaluators"
                  :indexRow="scope.$index"
                  :disableOptionFieldType="disableOptionFieldType"
                  :inputFieldType="inputFieldType"
                  :deleteAnd="deleteAnd"
                  :updateEvaluatorValue="updateEvaluatorValue"
                  :deleteEvaluatorValue="deleteEvaluatorValue"
                  :changeEvaluator="changeEvaluator"
                  :addEvaluatorTagValue="addEvaluatorTagValue"
                ></criteria-list>
                <div v-if="showButtonAndAtIndex(scope.$index)" class="row">
                  <div class="col-sm-12">
                    <a
                      href="javascript:void(0);"
                      @click="btnAnd(scope.$index)"
                      class="btn-and"
                    >+ AND</a>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column min-width="100" label="Min. Stay" header-align="center">
              <template scope="scope">
                <ValidationProvider name="minApply" rules="required" v-slot="{ passed, failed }">
                  <fg-input
                    class="fg-input-top"
                    name="minApply"
                    :error="
                            failed
                              ? 'Minimal to apply is required'
                              : null
                          "
                    :hasSuccess="passed"
                    v-model="scope.row.field_condition.min_stay"
                    type="number"
                  ></fg-input>
                </ValidationProvider>
              </template>
            </el-table-column>
            <el-table-column min-width="100" label="Max. Stay" header-align="center">
              <template scope="scope">
                <ValidationProvider name="minStay" rules="required" v-slot="{ passed, failed }">
                  <fg-input
                    class="fg-input-top"
                    name="minStay"
                    :error="
                            failed
                              ? 'Minimal to stay is required'
                              : null
                          "
                    :hasSuccess="passed"
                    v-model="scope.row.field_condition.max_stay"
                    type="number"
                  ></fg-input>
                </ValidationProvider>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="pointBy" label="Revenue Type" header-align="center">
              <template scope="scope">
                <div class="form-group">
                  <ValidationProvider name="pointBy" rules="required" v-slot="{ failed }">
                    <el-select
                      name="pointBy"
                      class="select-info"
                      size="large"
                      placeholder="Select point by"
                      v-model="scope.row.field_condition.point_by"
                    >
                      <el-option
                        v-for="option in pointByAll"
                        class="select-info"
                        :value="option.code"
                        :label="option.label"
                        :key="option.code"
                      ></el-option>
                    </el-select>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `Point by is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="100" prop="level" label="Level" header-align="center">
              <template scope="scope">
                <div class="form-group">
                  <ValidationProvider name="level" rules="required" v-slot="{ failed }">
                    <el-select
                      name="level"
                      class="select-info"
                      size="large"
                      placeholder="Select point by"
                      v-model="scope.row.field_condition.level"
                    >
                      <el-option
                        v-for="option in levelAll"
                        class="select-info"
                        :value="option.level"
                        :label="option.level"
                        :key="option.level"
                      ></el-option>
                    </el-select>
                    <span class="text-danger invalid-feedback" style="display: block;">
                      {{
                      failed
                      ? `Level is required`
                      : null
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="70" header-align="center" label="Actions">
              <template slot-scope="scope">
                <div class="text-center table-actions" style="margin-top: 3px;">
                  <el-tooltip content="Delete" :open-delay="300" placement="top">
                    <n-button @click.native="btnDeleteCriteria(scope)" type="danger" size="sm" icon>
                      <i class="now-ui-icons ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <n-button
            @click.native="btnAddCriteria"
            type="info"
            class="btn-border btn-border-small"
          >ADD CRITERIA</n-button>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option } from "element-ui";

import CriteriaList from "../CriteriaList";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    "criteria-list": CriteriaList,
  },
  created: function () {
    this.getLevelBonus();
  },
  data() {
    return {
      loading: false,
      evaluators: [],
    };
  },
  computed: {
    ...mapState("pointBy", ["pointByAll"]),
    ...mapState("bonusPoint", ["bonusPoint"]),
    ...mapState("levelbonus", ["levelAll"]),
    ...mapGetters("bonusPoint", [
      "showButtonAndAtIndex",
      "disableOptionFieldType",
      "inputFieldType",
    ]),
  },
  methods: {
    ...mapActions("levelbonus", ["getLevelBonus"]),
    ...mapMutations("bonusPoint", [
      "ADD_CODITION",
      "DELETE_CONDITION",
      "DELETE_AND",
      "ADD_AND",
      "DELETE_AND",
      "UPDATE_EVALUATOR_VALUE",
      "DELETE_EVALUATOR_VALUE",
      "CHANGE_EVALUATOR",
      "ADD_EVALUATOR_TAG_VALUE",
    ]),
    btnAnd: function (indexRow) {
      this.ADD_AND(indexRow);
    },
    btnAddCriteria: function () {
      this.ADD_CODITION();
    },
    btnDeleteCriteria: function (scope) {
      this.DELETE_CONDITION(scope.$index);
    },
    onChangeEvaluators: function () {},
    deleteAnd: function (payload) {
      this.DELETE_AND(payload);
    },
    updateEvaluatorValue: function (payload) {
      this.UPDATE_EVALUATOR_VALUE(payload);
    },
    deleteEvaluatorValue: function (payload) {
      this.DELETE_EVALUATOR_VALUE(payload);
    },
    changeEvaluator: function (payload) {
      this.CHANGE_EVALUATOR(payload);
    },
    addEvaluatorTagValue: function (payload) {
      this.ADD_EVALUATOR_TAG_VALUE(payload);
    },
  },
};
</script>
<style scoped>
/* .form-group {
  margin-bottom: 0px !important;
} */
.btn-and {
  color: #52c0e9;
}
.el-select {
  width: 100%;
  height: 50px;
}
.align-top {
  vertical-align: top;
}
/* .fg-input-top {
  margin-top: 10px;
} */
</style>
